import API from "../../apiWrapper";
import { User } from "../../../interfaces/user/User";
import { Email } from "../../../interfaces/common/Email";
import { UserRole } from "../../../interfaces/systemCatalog/UserRole";
import { Campus } from "../../../interfaces/systemCatalog/ICampus";
import { EmptyGuid } from "../../../utils/constants";
import { CostOfAttendance, EnrollmentProgram } from "../../../interfaces/student/academics/EnrollmentProgram";

export const getProgramScheduleDetails = (
	scheduleId?: string,
	enrollmentId?: string
) => {
	return API()
		.get("/AcademicRecords/ProgramScheduleDetails/GetProgramScheduleDetails", {
			params: {
				scheduleId,
				enrollmentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const getEnrollmentProgramInfo = (enrollmentId: string) => {
	return API()
		.get("/AcademicRecords/Enrollment/GetEnrollmentProgramInfo", {
			params: {
				enrollmentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const getNextBadgeNumber = (campusId: string, studentId?: string) => {
	return API()
		.get("/AcademicRecords/StudentSummary/GetNewBadgeNumber", {
			params: {
				campusId,
				studentId
			}
		})
		.then(
			(res: any) => {
				if (res && res) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const updateEnrollment = (enrollment: EnrollmentProgram) => {
	return API()
		.post("/AcademicRecords/Enrollment/UpdateEnrollment", enrollment)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const updateCostOfAttendance = (enrollment: CostOfAttendance) => {
	return API()
		.post("/AcademicRecords/Enrollment/UpdateCostOfAttendance", enrollment)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const getCostOfAttendance = (studentId: string, programVersionId: string) => {
	return API()
		.get("/AcademicRecords/Enrollment/GetAttendDetails",  {
			params: {
				studentId, programVersionId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};


export const createEnrollment = (enrollment: EnrollmentProgram | any) => {
	return API()
		.post("/AcademicRecords/Enrollment/CreateEnrollment", enrollment)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const GetTransferDetails = (studentId: string, programVersionId: string) => {
	return API()
		.get("/AcademicRecords/Enrollment/GetTransferDetails",  {
			params: {
				studentId, programVersionId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const checkStudentNumberAvailability = async (studentNumber : any, campusId : string | null) => {
	return await API()
	.post("/AcademicRecords/Enrollment/CheckStudentNumberAvailability", null ,  {
	  params: {studentNumber, campusId }
  })
	.then(
	  (res: any) => {
		  if (res && res.data) {
			  return res;
		  }
		  },
			  (error: any) => {
			  return error;
		  }
	  );
};

export const isExemptedForLicensureFor2020 = (stuEnrollId: string) => {
	return API()
		.get("/AcademicRecords/Enrollment/IsExemptedForLicensureFor2020", {
			params: {
				stuEnrollId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const updateExemptForLicensureFor2020 = (stuEnrollId: string,isExemptedForLicensureFor2020:boolean) => {
	return API()
		.patch(`/AcademicRecords/Enrollment/UpdateExemptForLicensureFor2020?stuEnrollId=${stuEnrollId}&isExemptedForLicensureFor2020=${isExemptedForLicensureFor2020}`)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

