import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  TextField,
  Button,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Paper } from "@material-ui/core";
import ExamsTable from "./ExamsTable";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import { useSelector } from "react-redux";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import * as postGradesApi from "../../../api/student/academics/postGradesApi";
import { useForm, FormContext } from "react-hook-form";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import { ITermSearchParam } from "../../../interfaces/setup/creditHour/ITermSearchModels";
import SortIcon from "@material-ui/icons/Sort";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import CreditAttemptHistoryBox from "../grades/CreditAttemptHistoryBox";
import GradeSystemDetailAutoComplete from "../../../components/AutoComplete/GradeSystemDetailAutoComplete";
import {
  IGradeComponent,
  IGradeComponentModel,
  ICreditOverallGPAModel,
} from "../../../interfaces/student/academics/PostGradesByClassFilter";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { result } from "lodash";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "95%",
      minHeight: "100%",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      overflowX: "hidden",
      overflowY: "hidden",
    },
    componentActions: {
      marginTop: theme.spacing(3),
    },
    gradesWrapper: {
      height: "calc(100% - 110px)",
      overflowY: "scroll",
      overflowX: "hidden",
      paddingTop: theme.spacing(2),
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
    right: {
      float: "right",
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
    attemptsSty: {
      width: 20,
    },
    scoreSty: {
      width: 170,
    },
    defaultDateSty: {
      width: 170,
    },
    buttonSection: {
      paddingTop: theme.spacing(2),
    },
    errorRow: {
      backgroundColor: "rgb(255, 201, 201)",
    },
    tableContainer: {
      width: "100%",
    },
    accordion: {
      position: "inherit",
      padding: 0,
    },
    accordionSummary: {
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0, 2),
    },
    accordionDetails: {
      display: "block",
      padding: theme.spacing(0.5, 2),
    },
    spanRight: {
      float: "right",
    },
    spanMid: {
      float: "left",
      fontWeight: "bold",
      textDecoration: "underline",
      paddingLeft: theme.spacing(5),
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    typography: {
      whiteSpace: "pre-line",
    },
    saveButton: {
      width: "auto",
      marginRight: theme.spacing(2),
    },
    gradeBlock: {
      paddingBottom: theme.spacing(2),
    },
    gradeStyle: {
      fontWeight: "bold",
    },
    footerDiv: {
      height: "75px",
    },
    pageStyle: {
      height: "100%",
    },
    body: {
      height: "calc(100% - 75px)",
    },
  })
);
type IGradesProps = {
  setConfirmationModal: any;
  stuEnrollId: string;
};

const CreditExams = (props: IGradesProps) => {
  const classes = useStyles({});
  const history = useHistory();
  const [validComponents, setValidComponents] = React.useState<
    IGradeComponentModel[]
  >([]);
  const [gradesData, setGradesData] = React.useState<StudentGrade[]>([]);
  const [selectedClasses, setSelectedClasses] = React.useState<string[]>([]);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [overallGPA, setOverallGPA] =
    React.useState<ICreditOverallGPAModel | null>(null);
  const [components, setComponents] = React.useState<Array<IGradeComponent>>(
    new Array<IGradeComponent>()
  );
  const [componentsCopy, setComponentsCopy] = React.useState<
    Array<IGradeComponent>
  >(new Array<IGradeComponent>());

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const ThemeTextField = withStyles((theme: any) =>
    createStyles({
      root: {},
    })
  )(TextField);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const defaultFilter: ITermSearchParam = {
    campusId: null,
    stuEnrollId: props.stuEnrollId,
    sortOrder: "ASC",
    pageNo: 1,
    pageSize: 100,
  };

  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const [showUnsavedChangesPopup, _setShowUnSavedChangesPopup] = React.useState<boolean>(false);
  const showUnsavedChangesPopupRef = React.useRef(showUnsavedChangesPopup);

  const setShowUnSavedChangesPopup = (v:boolean) =>{
    showUnsavedChangesPopupRef.current = v;
    _setShowUnSavedChangesPopup(v);
  }

  const [filter, setFilter] = React.useState<ITermSearchParam>(defaultFilter);

  const loadGradeComponents = () => {
    setFilter({ ...filter, stuEnrollId: props.stuEnrollId });
    postGradesApi
      .GetGradeComponentsByStudent({
        ...filter,
        stuEnrollId: props.stuEnrollId,
      })
      .then(
        (response: any) => {
          if (response && response.data) {
            var results: IGradeComponent[] = JSON.parse(
              response.data?.jsonResult
            );
            setComponents(results ?? []);
            setComponentsCopy(results ?? []);
            setIsDirty(false);
          }
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
  };

  const GetStudentOverallGPA = () => {
    postGradesApi.GetStudentOverallGPA(props.stuEnrollId).then(
      (response: any) => {
        if (response && response.data) {
          setOverallGPA(response.data);
        }
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleClose = () => {
    setDialogOpen(false);
    setLoaderState(false);
  };

  React.useEffect(() => {
    GetStudentOverallGPA();
    loadGradeComponents();
  }, [props.stuEnrollId]);

  const [nextUrl, setNextUrl] = React.useState<string>();
  const [nextParam, setNextParam] = React.useState<string>();

  React.useEffect(() => {
    const unblock = history.block((location:any, action:any) => {
      if (isDirty) {
        setNextUrl(location.pathname);
        setNextParam(location.search);
        setShowUnSavedChangesPopup(true);
        return false;
      }
    });
  
    return () => {
      unblock();
    };
  }, [[components, isDirty]]);

  const refreshGridData = () => {
    GetStudentOverallGPA();
    loadGradeComponents();
  };

  const { handleSubmit } = methods;

  const onRecalculate = (classId: string) => {
    postGradesApi
      .CalculateFinalGradesByClass(props.stuEnrollId, classId, true)
      .then(
        (response: any) => {
          if (response && response.data) {
            GetStudentOverallGPA();
            loadGradeComponents();
          }
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
  };

  const onSave = (pathToNavigate?:string, params?:any) => {
    setLoaderState(true);
    setSelectedClasses([]);

    let errorCount = 0;
    const updatedComponents = components.map((component) => {
      if (component.IsDirty) {
        if (
          ((component.Score !== null || component.GrdSysDetailId != null) &&
            !!component.DefaultDate &&
            moment(component.DefaultDate).isValid()) ||
          (component.Score == null &&
            component.GrdSysDetailId == null &&
            component.DefaultDate == undefined)
        ) {
          return { ...component, RowError: false };
        } else {
          errorCount = errorCount + 1;
          return { ...component, RowError: true };
        }
      } else {
        return component;
      }
    });
    setComponents(updatedComponents);

    var finalComponents: IGradeComponent[] = [];
    var validCompList: IGradeComponentModel[] = [];
    updatedComponents.forEach((comp) => {
      if (
        comp.RowError == false &&
        comp.IsDirty == true &&
        moment(comp.DefaultDate).isValid() &&
        (comp.Score !== null || comp.GrdSysDetailId != null)
      ) {
        finalComponents.push(comp);
        validCompList.push({
          stuEnrollId: props.stuEnrollId,
          score: Number(comp.Score),
          grdSysDetailId: comp.GrdSysDetailId,
          dateCompleted: comp.DefaultDate,
          instrGrdBkWgtDetailId: comp.InstrGrdBkWgtDetailId,
          isFinalGrade: comp.IsFinalGrade,
          instrClassTermId: comp.ClassId,
        });
      }
    });
    setValidComponents(validCompList);

    if (errorCount == 0 && validCompList.length > 0) {
      const distinctInstrClassTermId = Array.from(
        new Set(
          finalComponents
            .filter((f) => f.IsFinalGrade == false)
            .map((cm) => cm.ClassId)
        )
      );
      const overriddenComponents = components.filter(
        (cm) => cm.IsOverridden && distinctInstrClassTermId.includes(cm.ClassId)
      );
      const distinctTermDesc = Array.from(
        new Set(overriddenComponents.map((component) => component.Class))
      );
      setSelectedClasses(distinctTermDesc);

      if (distinctTermDesc.length > 0) {
        setDialogOpen(true);
      } else {
        handleConfirm(validCompList, false, pathToNavigate, params);
      }
    } else {
      setLoaderState(false);
    }
  };

  const handleConfirm = (comp: IGradeComponentModel[], override: boolean, pathToNavigate?:string, params?:any) => {
    postGradesApi.postCreditGradesByStudent(comp, override).then(
      (response: any) => {
        if (response && response.data) {
          GetStudentOverallGPA();
          loadGradeComponents();
          setDialogOpen(false);

          setSnackBarProps({
            variant: "success",
            showSnackBar: true,
            messageInfo: "Data saved successfully.",
          });
        }
        setIsDirty(false);
        setLoaderState(false);
        setTimeout(() => {
          if(!!pathToNavigate){
            history.push({
              pathname: pathToNavigate,
              search: params,
            });
          }
        },300);
      },
      (exception: any) => {
        setLoaderState(false);
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: "Error on posting grades.",
        });
      }
    );
  };

  const deleteFinalGrade = (instrClassTermId: string) => {
    setLoaderState(true);

    postGradesApi
      .deleteFinalCreditGradesByStudent(props.stuEnrollId, instrClassTermId)
      .then(
        (response: any) => {
          if (response && response.data) {
            GetStudentOverallGPA();
            loadGradeComponents();

            setSnackBarProps({
              variant: "success",
              showSnackBar: true,
              messageInfo: "Deleted successfully.",
            });
          }
          setLoaderState(false);
        },
        (exception: any) => {
          setLoaderState(false);
          setSnackBarProps({
            variant: "error",
            showSnackBar: true,
            messageInfo: "Failed to delete.",
          });
        }
      );
  };

  const distinctTerms = React.useMemo(() => {
    const distinctPairs = [];
    const map = new Map();
    for (const result of components) {
      const { TermId, TermDesc } = result;
      const key = `${TermId}-${TermDesc}`;
      if (!map.has(key)) {
        map.set(key, true);
        distinctPairs.push({ TermId, TermDesc });
      }
    }
    distinctPairs.sort((a, b) => {
      if (filter.sortOrder === "ASC") {
        return a.TermDesc.localeCompare(b.TermDesc);
      } else {
        return b.TermDesc.localeCompare(a.TermDesc);
      }
    });
    return distinctPairs;
  }, [components, filter]);

  const distinctClasses = React.useMemo(() => {
    const distinctPairs = [];
    const map = new Map();
    for (const result of components) {
      const { ClassId, Class, TermId } = result;
      const key = `${ClassId}-${Class}`;
      if (!map.has(key)) {
        map.set(key, true);
        distinctPairs.push({ ClassId, Class, TermId });
      }
    }
    return distinctPairs;
  }, [components]);

  const handleTextFieldClick = (event: any) => {
    event.stopPropagation();
  };

  const handleSearch = (searchText: string) => {
    const filteredComponents = componentsCopy.filter(
      (result) =>
        result.TermDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
        result.Course?.toLowerCase().includes(searchText.toLowerCase()) ||
        result.Class?.toLowerCase().includes(searchText.toLowerCase()) ||
        result.Component?.toLowerCase().includes(searchText.toLowerCase())
    );
    setComponents(filteredComponents);
    setSearchText(searchText);

    setExpandClasses([]);
    const newArray: string[] = [];
    filteredComponents.forEach((item, index) => newArray.push(item.ClassId));
    setExpandClasses(newArray);

    setExpandTerms([]);
    const newArrayT: string[] = [];
    filteredComponents.forEach((item, index) => newArrayT.push(item.TermId));
    setExpandTerms(newArrayT);
  };

  const [isTermExpanded, setIsTermExpanded] = React.useState<boolean>(false);
  const [isClassesExpanded, setIsClassesExpanded] =
    React.useState<boolean>(false);
  const [expandTerms, setExpandTerms] = React.useState<string[]>([]);
  const [expandClasses, setExpandClasses] = React.useState<string[]>([]);

  const termClicked = (index: string) => {
    if (expandTerms.includes(index))
      setExpandTerms(expandTerms.filter((number) => number !== index));
    else setExpandTerms([...expandTerms, index]);
  };

  const classClicked = (index: string) => {
    if (expandClasses.includes(index))
      setExpandClasses(expandClasses.filter((number) => number !== index));
    else setExpandClasses([...expandClasses, index]);
  };

  const expandClass = () => {
    if (isClassesExpanded) {
      setExpandClasses([]);
    } else {
      const newArray: string[] = [];
      components.forEach((item, index) => newArray.push(item.ClassId));
      setExpandClasses(newArray);
    }
    setIsClassesExpanded(!isClassesExpanded);
  };

  const expandTerm = () => {
    if (isTermExpanded) {
      setExpandTerms([]);
    } else {
      const newArray: string[] = [];
      components.forEach((item, index) => newArray.push(item.TermId));
      setExpandTerms(newArray);
    }
    setIsTermExpanded(!isTermExpanded);
  };

  const handelScore = (
    Score: string,
    InstrGrdBkWgtDetailId: string,
    ix: number
  ) => {
    let uComp = [...components];
    uComp[ix].IsDirty = true;
    if (Score != "" && isNumber(Score)) {
      uComp[ix].Score = Score;
      uComp[ix].GrdSysDetailId = null;
    } else {
      uComp[ix].Score = null;
    }
    uComp[ix].RowError =
      (uComp[ix].Score != null && uComp[ix].DefaultDate == null) ||
      (uComp[ix].Score == null && uComp[ix].DefaultDate != null)
        ? true
        : false;
    setComponents([...uComp]);
    setIsDirty(true);
  };

  const handelGrade = (
    GradeSys: string,
    InstrGrdBkWgtDetailId: string,
    ix: number
  ) => {
    let uComp = [...components];
    const isGradeDirty = uComp[ix].GrdSysDetailId != GradeSys ? true : false;
    uComp[ix].IsDirty = isGradeDirty;
    uComp[ix].GrdSysDetailId = GradeSys ?? null;
    uComp[ix].Score = null;
    uComp[ix].RowError =
      (uComp[ix].GrdSysDetailId != null && uComp[ix].DefaultDate == null) ||
      (uComp[ix].GrdSysDetailId == null && uComp[ix].DefaultDate != null)
        ? true
        : false;
    setComponents([...uComp]);
    setIsDirty(isGradeDirty);
  };

  const changeDefaultDate = (
    dDate: Date,
    InstrGrdBkWgtDetailId: string,
    ix: number
  ) => {
    let uComp = [...components];
    uComp[ix].DefaultDate = dDate;
    uComp[ix].IsDirty = true;
    uComp[ix].RowError =
      (uComp[ix].DefaultDate != null &&
        uComp[ix].IsFinalGrade == true &&
        uComp[ix].GrdSysDetailId == null) ||
      (uComp[ix].DefaultDate != null &&
        uComp[ix].IsFinalGrade == false &&
        uComp[ix].Score == null) ||
      (uComp[ix].DefaultDate == null &&
        uComp[ix].IsFinalGrade == true &&
        uComp[ix].GrdSysDetailId != null) ||
      (uComp[ix].DefaultDate == null &&
        uComp[ix].IsFinalGrade == false &&
        uComp[ix].Score != null)
        ? true
        : false;

    setComponents([...uComp]);
    setIsDirty(true);
  };

  const isNumber = (value: string) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  return (
    <Paper className={classes.root}>
            <React.Fragment>
      <Dialog
			open={showUnsavedChangesPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomCardTitle title='Unsaved Changes' />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{'There are unsaved changes. Would you like to save?'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					onSave(nextUrl, nextParam);
          setShowUnSavedChangesPopup(false);
				}} color="primary">
					Yes
				</Button>
				<Button onClick={() => {
          setIsDirty(false);
          setShowUnSavedChangesPopup(false);
          setTimeout(() => {
            history.push({
              pathname: nextUrl,
              search: nextParam,
            });
          },1000);
        }} color="primary">
					No
				</Button>
			</DialogActions>
		</Dialog>
      </React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <div className={classes.pageStyle}>
        <div className={classes.body}>
          <FormContext {...methods}>
            <Grid container direction="column" spacing={1}>
              <Grid container lg={12} xl={12} xs={12} direction="row">
                <Grid item md={4} sm={4} xs={12}>
                  <Typography className={classes.gradeBlock}>
                    <span className={classes.gradeStyle}>Overall GPA </span>{" "}
                    {(overallGPA?.overallGPA ?? 0).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Typography className={classes.gradeBlock}>
                    <span className={classes.gradeStyle}>Credits Earned </span>{" "}
                    {(overallGPA?.creditEarned ?? 0).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Typography className={classes.gradeBlock}>
                    <span className={classes.gradeStyle}>
                      Credit Attempted{" "}
                    </span>{" "}
                    {(overallGPA?.creditAttempted ?? 0).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container lg={12} xl={12} xs={12} direction="row">
                <Grid item md={4} sm={4} xs={12}>
                  
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Typography className={classes.gradeBlock}>
                    <span className={classes.gradeStyle}>FA Credits Earned </span>{" "}
                    {(overallGPA?.faCreditEarned ?? 0).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Typography className={classes.gradeBlock}>
                    <span className={classes.gradeStyle}>
                      FA Credit Attempted{" "}
                    </span>{" "}
                    {(overallGPA?.faCreditAttempted ?? 0).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container lg={12} xl={12} xs={12} direction="row">
                <Grid item md={2} sm={2} xs={12}>
                  <IconButton
                    onClick={() => {
                      const newSort =
                        filter.sortOrder === "ASC" ? "DESC" : "ASC";
                      setFilter({ ...filter, sortOrder: newSort });
                    }}
                  >
                    <SortIcon
                      className={
                        (filter.sortOrder === "ASC" && classes.sortDesc) || ""
                      }
                    />
                  </IconButton>
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                  <Button variant="contained" onClick={expandTerm}>
                    Show Classes
                  </Button>
                  <Button variant="contained" onClick={expandClass}>
                    Show Components
                  </Button>
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                  <div className={classes.right}>
                    <TextField
                      placeholder={"Search..."}
                      onClick={handleTextFieldClick}
                      value={searchText}
                      onChange={(v: any) => {
                        handleSearch(v ? v.target.value : undefined);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.gradesWrapper}>
              <Grid container direction="column" spacing={1}>
                <Grid container lg={12} xl={12} xs={12} direction="column">
                  <Grid container direction="column" spacing={2}>
                    {distinctTerms.map(({ TermId, TermDesc }) => (
                      <Accordion
                        key={TermId}
                        onChange={() => termClicked(TermId)}
                        expanded={expandTerms.includes(TermId)}
                        className={classes.accordion}
                      >
                        <AccordionSummary
                          /* expandIcon={<ExpandMoreIcon />} */
                          className={classes.accordionSummary}
                        >
                          <Typography>{TermDesc}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {distinctClasses
                            .filter((result) => result.TermId === TermId)
                            .map(({ ClassId, Class }) => (
                              <Accordion
                                key={ClassId}
                                onChange={() => classClicked(ClassId)}
                                expanded={expandClasses.includes(ClassId)}
                                className={classes.accordion}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  className={classes.accordionSummary}
                                >
                                  <Typography>
                                    {" "}
                                    <b>{Class}</b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={classes.accordionDetails}
                                >
                                  <TableContainer
                                    key={ClassId}
                                    className={classes.tableContainer}
                                  >
                                    <Table
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Component</TableCell>
                                          <TableCell
                                            className={classes.scoreSty}
                                          >
                                            Score
                                          </TableCell>
                                          <TableCell
                                            className={classes.defaultDateSty}
                                          >
                                            Date
                                          </TableCell>
                                          <TableCell
                                            className={classes.attemptsSty}
                                          >
                                            {" "}
                                          </TableCell>
                                          <TableCell
                                            className={classes.attemptsSty}
                                          >
                                            Attempts
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {components
                                          .filter(
                                            (result) =>
                                              result.ClassId === ClassId
                                          )
                                          .sort((a, b) => a.Seq - b.Seq)
                                          .map((component, index) => (
                                            <TableRow
                                              key={index}
                                              className={
                                                component.RowError == true
                                                  ? classes.errorRow
                                                  : ""
                                              }
                                            >
                                              <TableCell>
                                                {component.IsFinalGrade ? (
                                                  <span
                                                    className={classes.spanMid}
                                                  >
                                                    {"Current Avg: " +
                                                      (component.Average ?? "")}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                {component.IsFinalGrade ? (
                                                  <span
                                                    className={
                                                      classes.spanRight
                                                    }
                                                  >
                                                    {component.Component}{" "}
                                                    {component.IsOverridden
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {component.Component}
                                                  </span>
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                {component.IsFinalGrade ? (
                                                  <GradeSystemDetailAutoComplete
                                                    id={
                                                      component.RowNo +
                                                      "GradeSys"
                                                    }
                                                    name={
                                                      component.RowNo +
                                                      "GradeSys"
                                                    }
                                                    key={
                                                      component.RowNo +
                                                      "GradeSys"
                                                    }
                                                    grdSystemId={
                                                      component?.GrdSystemId
                                                    }
                                                    valueFilter={
                                                      component?.GrdSysDetailId
                                                        ? {
                                                            key: "value",
                                                            values: [
                                                              component.GrdSysDetailId,
                                                            ],
                                                          }
                                                        : undefined
                                                    }
                                                    filterHandle={(v: any) => {
                                                      handelGrade(
                                                        v ? v.value : null,
                                                        component.InstrGrdBkWgtDetailId,
                                                        component.RowNo - 1
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <InputField
                                                    type={InputType.NUMBER}
                                                    name="Score"
                                                    key="Score"
                                                    decimal
                                                    thousandSeparator
                                                    defaultValue={
                                                      component.Score
                                                    }
                                                    onBlur={(
                                                      v: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                      handelScore(
                                                        v.target.value,
                                                        component.InstrGrdBkWgtDetailId,
                                                        component.RowNo - 1
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                <MuiPickersUtilsProvider
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    className={
                                                      classes.datePickerField
                                                    }
                                                    disableToolbar
                                                    autoOk={true}
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id={
                                                      "dateCompleted_" +
                                                      component.InstrGrdBkWgtDetailId
                                                    }
                                                    key={
                                                      "key_dateCompleted_" +
                                                      component.InstrGrdBkWgtDetailId
                                                    }
                                                    name={
                                                      "dateCompleted_" +
                                                      component.InstrGrdBkWgtDetailId
                                                    }
                                                    //   disableFuture={true}
                                                    // maxDate={d}
                                                    value={
                                                      component.DefaultDate
                                                        ? new Date(
                                                            component.DefaultDate
                                                          )
                                                        : null
                                                    }
                                                    onChange={(v: any) => {
                                                      changeDefaultDate(
                                                        v ? v : null,
                                                        component.InstrGrdBkWgtDetailId,
                                                        component.RowNo - 1
                                                      );
                                                    }}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                    invalidDateMessage={""}
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </TableCell>
                                              {!component.IsFinalGrade ? (
                                                <>
                                                  <TableCell>
                                                    {component.Attempts}
                                                  </TableCell>
                                                  <TableCell>
                                                    {component.Attempts > 0 && (
                                                      <CreditAttemptHistoryBox
                                                        gradeBookWeightDetailId={
                                                          component.InstrGrdBkWgtDetailId
                                                        }
                                                        showAdj={false}
                                                        showScore={true}
                                                        lblAdj={"Adj"}
                                                        tabType={"Exams"}
                                                        excludeLast={false}
                                                        setSnackBar={
                                                          setSnackBarProps
                                                        }
                                                        refreshGridData={
                                                          refreshGridData
                                                        }
                                                        setConfirmationModal={
                                                          props.setConfirmationModal
                                                        }
                                                        instrClassTermId={
                                                          component.ClassId
                                                        }
                                                        isOverridden={(components?.filter(f => f.ClassId == ClassId && f.IsOverridden == true)).length == 0 ? false : true}
                                                        studentName={Class}
                                                        stuEnrollId={props.stuEnrollId}
                                                        attempts={
                                                          component.Attempts
                                                        }
                                                      />
                                                    )}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell>
                                                    <Button
                                                      variant="contained"
                                                      key={component.ClassId}
                                                      onClick={() => {
                                                        onRecalculate(ClassId);
                                                      }}
                                                    >
                                                      Recalculate
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    {!!component.GrdSysDetailId && (
                                                      <Button
                                                        variant="contained"
                                                        key={component.ClassId}
                                                        onClick={() => {
                                                          deleteFinalGrade(
                                                            component.ClassId
                                                          );
                                                        }}
                                                      >
                                                        Delete
                                                      </Button>
                                                    )}
                                                  </TableCell>
                                                </>
                                              )}
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </FormContext>
        </div>
        <div className={classes.footerDiv}>
          <Grid container lg={12} xl={12} xs={12} direction="row">
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.buttonSection}
            >
              <Grid item xs={9} container direction="row" spacing={1}>
                <AccessManager
                  allowedPermissions={[
                    security.permissions.student.updateExamResults,
                    security.permissions.student.deleteExamResults,
                  ]}
                  renderNoAccess={() => (
                    <ProgressSaveButton
                      disabled={true}
                      text="Save"
                      onClick={() => {
                        onSave();
                      }}
                      loading={loaderState}
                    ></ProgressSaveButton>
                  )}
                >
                  <ProgressSaveButton
                    text="Save"
                    onClick={() => {
                      onSave();
                    }}
                    loading={loaderState}
                  ></ProgressSaveButton>
                </AccessManager>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "right" }}>
                <Typography>* Overridden Final Grade</Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Recalculate Grades Confirmation"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                className={classes.typography}
                variant="body1"
              >
                {
                  "Would you like to recalculate the final grade for the following class(es)?"
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {selectedClasses?.map((cls: string, ix: any) => (
                      <TableRow>
                        <TableCell>{cls}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid item xs={12}>
          <DialogActions className={classes.buttonSection}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}></Grid>
              <Grid
                item
                container
                xs={6}
                alignContent="center"
                alignItems="center"
                justify="center"
              >
                <ProgressSaveButton
                  text={"Yes"}
                  onClick={() => {
                    handleConfirm(validComponents, true);
                  }}
                  loading={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
                <ProgressSaveButton
                  text={"No"}
                  onClick={() => {
                    handleConfirm(validComponents, false);
                  }}
                  loading={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </Paper>
  );
};
export default CreditExams;
