import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Card,
  CardContent,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import IncrementDetailsCard from "../academics/ProgramProgramVersion/ProgramVersion/ChargingMethod/IncrementDetailsCard";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(2, 0),
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type IChargeDefinitionVersionDetailModelProps = {
  isCreatingNew: boolean;
  closeChargingDefinition: any;
  model: ChargeDefinitionVersion;
  setModel: any;
};

function ChargeDefinitionVersionDetail(
  props: IChargeDefinitionVersionDetailModelProps
) {
  const classes = useStyles();

  const [chargeDefinitionDetailsModel, setChargeDefinitionDetailsModel] =
    React.useState<IChargingMethodIncrement>(new CharginMethodIncrement());

  return (
    <Card>
      <CustomCardTitle title={"Charge Definition Detail"} />
      <CardContent>
        <IncrementDetailsCard
          model={chargeDefinitionDetailsModel}
          setModel={setChargeDefinitionDetailsModel}
          isEditable={true}
          usesActualHours={true}
        />
        {/* 
					
					<Grid item md={3} sm={4} xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								id="effectiveDate"
								name="effectiveDate"
								label="Effective date *"
								TextFieldComponent={TextField}
								fullWidth
								autoOk={true}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								value={
									model.effectiveDate
										? new Date(model.effectiveDate).toLocaleDateString()
										: null
								}
								error={!!methods.errors.effectiveDate}
								inputRef={methods.register({
									required: true,
								})}
								helperText={
									methods.errors.effectiveDate
										? "Effective date is required"
										: null
								}
								onChange={(val: any) => {
									handleFieldOnChange(
										"effectiveDate",
										val.toLocaleDateString()
									);
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<InputField
							id="description"
							name="description"
							label="Description *"
							defaultValue={model.description ? model.description : undefined}
							error={!!data.errors.description}
							inputRef={data.register({
								required: true,
							})}
							helperText={
								data.errors.description ? "Description is required" : null
							}
							onBlur={(e: any) => {
								handleFieldOnChange(
									"description",
									e ? e.target.value : undefined
								);
							}}
							type={InputType.TEXT}
						/>
					</Grid>
					<Grid item md={5} sm={7} xs={12}>
						<InputField
							id="percentageOfExcusedAbsences"
							name="percentageOfExcusedAbsences"
							label="Percentage of Excused Absences to include in calculation *"
							defaultValue={
								model.percentageOfExcusedAbsences
									? model.percentageOfExcusedAbsences
									: undefined
							}
							error={!!data.errors.percentageOfExcusedAbsences}
							inputRef={data.register({
								required: true,
							})}
							helperText={
								data.errors.percentageOfExcusedAbsences
									? "Field is required"
									: null
							}
							onBlur={(e: any) => {
								handleFieldOnChange(
									"percentageOfExcusedAbsences",
									e ? e.target.value : undefined
								);
							}}
							type={InputType.NUMBER}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}></Grid>

					<Grid item xs={12}>
						<Button
							className={classes.buttonColorPrimary}
							onClick={() => {
								handelSaveDefinition();
							}}
							size="small"
							type="button"
						>
							Save Version
						</Button>
						<Button
							className={classes.buttonColorTertiary}
							size="small"
							onClick={closeChargingDefinition}
							type="button"
						>
							Cancel
						</Button>
					</Grid>
				 */}
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionVersionDetail;
