import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Typography,
  CardContent,
  Card,
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import clsx from "clsx";
import { security } from "../../../constants/Security/securityConstants";
import AccessManager from "../../../components/security/AccessManager";
import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import ChargeDefinitionVersionDetail from "./ChargeDefinitionVersionDetail";
import { ChargePeriod } from "../../../interfaces/setup/chargeDefinition/ChargePeriod";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },

    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1),
    },
    tableWrapper: {
      width: "60%",
    },
  })
);

function ChargeDefinitionVersions() {
  const classes = useStyles();
  const chargingDefinitionVersionModelDefault: ChargeDefinitionVersion = {
    chargeDefinitionVersionId: "",
    effectiveDate: "",
    description: "",
    percentageOfExcusedAbsences: 5,
    periods: new Array<ChargePeriod>(),
  };
  const [
    workingChargeDefinitionVersionModel,
    setWorkingChargeDefinitionVersionModel,
  ] = React.useState<ChargeDefinitionVersion | null>(null);

  const [
    isCreatingChargeDefinitionVersion,
    setIsCreatingChargeDefinitionVersion,
  ] = React.useState<boolean>(false);

  const [definitionVersions, setDefinitionVersions] = React.useState<
    Array<ChargeDefinitionVersion>
  >([
    {
      chargeDefinitionVersionId: "123",
      description: "Description 1",
      effectiveDate: "7/21/2020",
      percentageOfExcusedAbsences: 5,
      periods: new Array<ChargePeriod>(),
    },
    {
      chargeDefinitionVersionId: "124",
      description: "Description 2",
      effectiveDate: "7/21/2020",
      percentageOfExcusedAbsences: 5,
      periods: new Array<ChargePeriod>(),
    },
  ]);

  const [selected, setSelected] = React.useState([] as any);
  const isSelected = (definitionVersionId: any) =>
    selected.indexOf(definitionVersionId) !== -1;

  const selectAllClick = (event: any) => {
    if (event.target.checked) {
      const selected = definitionVersions.map(
        (n: any) => n.definitionVersionId
      );
      setSelected(selected);
      return;
    }
    setSelected([]);
  };
  const rowClick = (event: any, resultId: string) => {
    // const selectedIndex = selected.indexOf(resultId);
    // let newSelected: string[] = [];
    // if (selectedIndex === -1) {
    // 	newSelected = newSelected.concat(selected, resultId);
    // } else if (selectedIndex === 0) {
    // 	newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    // 	newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    // 	newSelected = newSelected.concat(
    // 		selected.slice(0, selectedIndex),
    // 		selected.slice(selectedIndex + 1)
    // 	);
    // }
    // setSelected(newSelected);
  };

  return (
    <Card>
      <CustomCardTitle title={"Charge Definition Versions"} />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.tableWrapper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Effective Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {definitionVersions.map((row: ChargeDefinitionVersion) => {
                    const isRowSelected = isSelected(
                      row.chargeDefinitionVersionId
                    );
                    return (
                      <TableRow
                        key={row.chargeDefinitionVersionId as any}
                        onClick={(event: any) =>
                          rowClick(event, row.chargeDefinitionVersionId as any)
                        }
                        role="checkbox"
                        selected={isRowSelected}
                      >
                        <TableCell align="left">
                          <a>{row.description}</a>
                        </TableCell>
                        <TableCell align="left">{row.effectiveDate}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            {!isCreatingChargeDefinitionVersion &&
              workingChargeDefinitionVersionModel == null && (
                <AccessManager
                  allowedPermissions={[
                    security.permissions.maintenance.UpdateChargeDefinition,
                  ]}
                  renderNoAccess={() => (
                    <Button
                      className={classes.buttonColorPrimary}
                      onClick={() => {}}
                      size="small"
                      type="button"
                      disabled={true}
                    >
                      Add New
                    </Button>
                  )}
                >
                  <Button
                    style={{ marginLeft: 5 }}
                    className={classes.buttonColorPrimary}
                    onClick={() => {
                      setIsCreatingChargeDefinitionVersion(true);
                      setWorkingChargeDefinitionVersionModel(
                        chargingDefinitionVersionModelDefault
                      );
                    }}
                    size="small"
                    type="button"
                  >
                    Add New
                  </Button>
                </AccessManager>
              )}
          </Grid>

          <Grid item xs={12}>
            {isCreatingChargeDefinitionVersion &&
              workingChargeDefinitionVersionModel != null && (
                <React.Fragment>
                  <ChargeDefinitionVersionDetail
                    isCreatingNew={isCreatingChargeDefinitionVersion}
                    model={workingChargeDefinitionVersionModel}
                    setModel={setWorkingChargeDefinitionVersionModel}
                    closeChargingDefinition={() => {
                      setIsCreatingChargeDefinitionVersion(false);
                      setWorkingChargeDefinitionVersionModel(null);
                    }}
                  ></ChargeDefinitionVersionDetail>
                </React.Fragment>
              )}
          </Grid>

          {isCreatingChargeDefinitionVersion &&
            workingChargeDefinitionVersionModel != null && (
              <React.Fragment>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Button
                      className={classes.buttonColorPrimary}
                      onClick={() => {
                        // handelSaveDefinition();
                      }}
                      size="small"
                      type="button"
                    >
                      Save Definition Version
                    </Button>
                    <Button
                      style={{ marginLeft: 5 }}
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {}}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
        </Grid>
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionVersions;
