import React, { Suspense, useState, useEffect, SyntheticEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/styles";
import { TopBar } from "./TopBar/TopBar";
import StudentQuickInfo from "./StudentQuickInfo/StudentQuickInfo";
import ComponentList from "../routing/ComponentList";
import AdvantageViewer from "../../components/Common/AdvantageViewer";
import AdvanceSearchResultsGrid from "../../pages/student/advanceSearchResults/AdvanceSearchResultsGrid";
import NavBar from "./NavBar/NavBar";
import { LinearProgress, Paper, Typography } from "@material-ui/core";
import * as userMenuApi from "../../api/user/userMenuApi";
import * as settingsApi from "../../api/system/settingsApi";
import * as actionTypes from "../../constants/actions.constants";
import { buildNavigationConfig } from "./NavBar/navigationConfig";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import UrlParamaterManager from "./UrlParameterManager";
import Toaster from "../../components/notifications/Toaster";
import PdfPreview from "../../components/Common/GenerateReport/PdfPreview";
import NewEnrollment from "../../pages/student/newEnrollment/NewEnrollment";
import NewEnrollmentSelectionModal from "../../pages/student/newEnrollment/NewEnrollmentSelectionModal";
import SelectedSchoolBackground from "../security/SchoolBackground";
import themeTenantPicker from "../../components/Common/ThemeTenantPicker";
import AdhocList from "../../pages/reports/AdHoc/AdhocList";
import NewAdhoc from "../../pages/reports/AdHoc/NewAdhoc";
import ReportDownloader from "../../pages/reports/ReportDownloader";

const MainLayout = (props: any) => {
  const { route } = props;
  const themeName = themeTenantPicker();
  const themeBackground = SelectedSchoolBackground(themeName);
  const useStyles = makeStyles((theme: any) =>
    createStyles({
      root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      },
      topBar: {
        zIndex: 10,
        position: "relative",
      },
      container: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        position: "relative",
        "@media screen and (-webkit-min-device-pixel-ratio:0)": {
          height: "900px",
        },
      },
      navBar: {
        zIndex: 3,
        width: 240,
        flex: "0 0 auto",
      },
      content: {
        overflowY: "auto",
        flex: "1 1 auto",
        background:
          themeName === "default"
            ? theme.palette.login.background
            : "url(" + themeBackground + ") no-repeat center center fixed",
        backgroundColor: theme.palette.white,
        backgroundSize: "cover",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        height: "100%",
        minHeight: "100%",
      },
      contentOpaque: {
        height: "100%",
        minHeight: "100%",
        width: "100%",
        backgroundColor: "rgba(255,255,255, 0.92)",
      },

      contentWrapper: {
        width: "100%",
        height: "90%",
        zIndex: 9999,
        backgroundColor: "transparent",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      centered: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
      },
      breadCrumbs: {
        width: "100%",
        lineHeight: 2,
        height: "6%",
        maxHeight: 75,
        minHeight: 50,
        backgroundColor: theme.palette.site.secondary,
      },
      footer: {
        height: "2%",
        maxHeight: "2%",
        paddingLeft: theme.spacing(3),
        fontStyle: "italic",
      },
    })
  );
  const classes = useStyles({});
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [routesConfig, setRoutesConfig] = useState<Array<any>>([]);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const userMenu = useSelector<any, any>(
    (state: any) => state.session.user.menu
  );
  const dispatch = useDispatch();

  dispatch({
    type: actionTypes.tabConstants.SESSION_TAB_CLEARED,
  });

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const createRoutesConfig = () => {
    let allLinks: Array<any> = [];

    //first level pages
    for (let i = 0; i < userMenu.pages.length; i++) {
      allLinks = allLinks.concat(userMenu.pages[i]);

      //second level pages
      if (userMenu.pages[i].children) {
        allLinks = allLinks.concat(userMenu.pages[i].children);

        userMenu.pages[i].children.forEach((childPage: any) => {
          //third level pages
          if (childPage.children) {
            allLinks = allLinks.concat(childPage.children);

            childPage.children.forEach((subChildPage: any) => {
              //fourth level pages
              if (subChildPage.children) {
                allLinks = allLinks.concat(subChildPage.children);
              }
            });
          }
        });
      }
    }

    // console.log("All links:", allLinks);

    let tempStaticData: Array<any> = allLinks.map((e, i) => {
      return { Url: e.route, ComponentName: e.component };
    });

    tempStaticData = tempStaticData.filter((e) => {
      if (e.Url) return true;
      else {
        return false;
      }
    });

    //remove distincts
    let flags: Array<any> = [],
      flags2: Array<any> = [],
      distinct = [],
      l = tempStaticData.length,
      i = 0;

    //distinct
    for (let i = 0; i < l; i++) {
      if (
        flags[tempStaticData[i].ComponentName] &&
        flags2[tempStaticData[i].Url]
      )
        continue;
      flags[tempStaticData[i].ComponentName] = true;
      flags2[tempStaticData[i].Url] = true;
      distinct.push(tempStaticData[i]);
    }
    setRoutesConfig(distinct as any);
  };

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const EnableClockHourCreditHour = appSettings.EnableClockHourCreditHour;

  useEffect(() => {
    if (userSelectedCampus) {
      userMenuApi.getUserMenu(userSelectedCampus).then(
        (response: any) => {
          if (response && response.length > 0) {
            dispatch({
              type: actionTypes.userConstants.SESSION_MENU_RETRIEVED,
              menu: buildNavigationConfig(
                response,
                EnableClockHourCreditHour === "False" ||
                  EnableClockHourCreditHour === "false"
              ),
            });
          }
        },
        (exception: any) => {}
      );
      settingsApi.getAppSettings(userSelectedCampus).then(
        (response: any) => {
          if (response) {
            dispatch({
              type: actionTypes.settingConstants.SET_SETTINGS,
              settings: response,
            });
          }
        },
        (exception: any) => {}
      );
    }
    if (userSelectedCampus && userMenu) createRoutesConfig();
  }, [userSelectedCampus, EnableClockHourCreditHour]);

  useEffect(() => {
    if (userMenu) createRoutesConfig();
  }, [userMenu]);

  if (
    props &&
    props.location &&
    (props.location.pathname === "/login" ||
      props.location.pathname === "" ||
      props.location.pathname === "/")
  ) {
    props.history.push({
      pathname: "/dashboard",
    });
    return <p>Redirecting</p>;
  }

  return (
    <div className={classes.root}>
      <UrlParamaterManager />
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          pages={userMenu}
          openMobile={openNavBarMobile}
        />

        <main className={classes.content}>
          <div className={classes.contentOpaque}>
            <Paper square={true} className={classes.breadCrumbs} elevation={1}>
              <BreadCrumbs />
            </Paper>
            <div className={classes.contentWrapper}>
              <Suspense fallback={<LinearProgress />}>
                {routesConfig.map(function (item: any, index: any) {
                  return (
                    <Route
                      exact
                      key={index}
                      path={item.Url}
                      component={ComponentList.GetComponentByName(
                        item.ComponentName
                      )}
                    />
                  );
                })}
                <Route path="/Advantage" component={AdvantageViewer} />
                <Route
                  path="/Student/NewEnrollment"
                  component={NewEnrollment}
                />
                <Route
                  path="/AdvanceSearchResultsGrid"
                  component={AdvanceSearchResultsGrid}
                />
                <Route path="/reports/adhoc" component={AdhocList} exact />
                <Route
                  path="/reports/adhoc/newAdhocReport"
                  component={NewAdhoc}
                />
                <Route
                  path="/reports/adhoc/report/:reportId"
                  component={NewAdhoc}
                />
                <Route
                  path="/reports/downloadReports"
                  component={ReportDownloader}
                />
              </Suspense>
            </div>
            <footer className={classes.footer}>
              <Typography variant="caption">
                <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
                Powered by FAME Advantage. | v5.2.8 |
              </Typography>
              <Typography color="primary" variant="caption">
                <b> What’s New</b>
              </Typography>
            </footer>
          </div>
        </main>
      </div>
      <StudentQuickInfo />

      <Toaster variant={"info"} message="" open={false}></Toaster>

      <PdfPreview open={false} url={""}></PdfPreview>
    </div>
  );
};

export default withRouter(MainLayout);
