import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import {
  CardContent,
  Card,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import * as programVersionApi from "../../../api/setup/academics/programVersionApi";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(2, 0),
    },
    tableWrapper: {
      width: "60%",
      height: 300,
      overflowY: "auto",
    },
  })
);

type IChargeDefinitionProgramVersionProps = {
	isCreatingNew: boolean;
	model: ChargeDefinition;
	setModel: any;
  };

function ChargeDefinitionProgramVersions(props: IChargeDefinitionProgramVersionProps) {
  const classes = useStyles();

  const [programVersions, setProgramVersions] = React.useState([] as any);
  const [selected, setSelected] = React.useState(props.model.chargeDefinitionProgramVersions || []);

  const isSelected = (programVersionId: any) =>
    selected.indexOf(programVersionId) !== -1;

  const selectAllClick = (event: any) => {
    if (event.target.checked) {
      const selected = programVersions.map((n: any) => n.programVersionId);
      setSelected(selected);
      return;
    }
    setSelected([]);
  };
  const rowClick = (event: any, resultId: string) => {
    const selectedIndex = selected.indexOf(resultId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, resultId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  React.useEffect(() => {
    if (campusId) {
      programVersionApi.GetProgramVersionList(campusId, true).then(
        (response: any) => {
          if (response) {
            const mappedData = response.map((item: any, index: number) => ({
              programVersionId: item.value,
              programVersionDescription: item.text,
            }));

            setProgramVersions(mappedData);
          }
        },
        (exception: any) => {}
      );
    }
  }, [campusId]);

  React.useEffect(() => {
	let updatedModel = props.model;
    (updatedModel as any)["chargeDefinitionProgramVersions"] = selected;
    props.setModel(updatedModel);
  }, [selected]);

  return (
    <Card>
      <CustomCardTitle title={"Charge Definition Program Versions"} />
      <CardContent>
        <div className={classes.tableWrapper}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      value="uncontrolled"
                      color="primary"
                      onClick={selectAllClick}
                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="left">Program Versions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programVersions.map((row: any) => {
                  const isRowSelected = isSelected(row.programVersionId);
                  return (
                    <TableRow
                      key={row.programVersionId}
                      onClick={(event) => rowClick(event, row.programVersionId)}
                      role="checkbox"
                      selected={isRowSelected}
                    >
                      <TableCell align="left">
                        <Checkbox
                          value="uncontrolled"
                          color="primary"
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                          checked={isRowSelected}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.programVersionDescription}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionProgramVersions;
