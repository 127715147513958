import React, { } from "react";
import { useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/styles";
import AdhocFieldViewer from "./AdhocFieldViewer";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Paper, Card, CardContent, Grid, CardActions, Table, TableHead, TableRow, TableCell, TableBody, IconButton, CircularProgress, Button, TableContainer } from "@material-ui/core";
import clsx from "clsx";
import { selectors as adHocDetailsSelectors, actions as adHocDetailsActions } from '../../../store/reports/adhocReportDetails';
import { IAdhocReportField } from "../../../interfaces/reports/adhoc/IAdhocReportField";
import { grey } from "@material-ui/core/colors";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import AdHocReportSortConfiguration from './AdHocReportSortConfiguration';
import AdHocReportFilterConfiguration from './AdHocReportFilterConfiguration';
import { IAdhocRerportColumnSortOption } from "../../../interfaces/reports/adhoc/IAdHocReportOptions";
import AscIcon from '@material-ui/icons/ArrowUpward';
import DescIcon from '@material-ui/icons/ArrowDownward';
import { AhHocReportColumnSortOption } from "../../../enums/AhHocReportColumnSortOption";
import { useActions } from "../../../store/utils";
import { useLocation, useParams, useHistory, Redirect } from "react-router-dom";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Pagination } from "@material-ui/lab";
import SaveAdHocReportPopUp from './SaveAdHocReportPopUp';
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { IAdhocReportDetails } from "../../../interfaces/reports/adhoc/IAdhocReportDetails";
import ExportButton from "./ExportButton";
import { system } from "../../../constants/system/systemConstants";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3),
            height: "100%",
            display: 'flex',
            flexDirection: 'column'
        },
        cardHeader: {
            '& span': {
                fontSize: "18px",
                fontWeight: "bold"
            },
            backgroundColor: theme.palette.site.secondary,

        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            overflow: "auto",
            position: "relative",
            padding: theme.spacing(1)
        },
        previewWindow: {
            height: '100%'
        },
        ninetyPercent: {
            height: '90%'
        },
        navBar: {
            zIndex: 3,
            width: 240,
            flex: "0 0 auto",
        },
        content: {
            overflowY: "auto",
            flex: "1 1 auto",
            backgroundColor: theme.palette.white,
            height: "100%",
            minHeight: "100%",
            marginLeft: theme.spacing(6)
        },
        fullHeight: { height: "100%", },
        rowPadding: {
            padding: theme.spacing(1)
        },
        dropArea: {
            height: "100%",
            overflow: "auto",
            padding: theme.spacing(0.25, 0.25),
            backgroundColor: grey[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            wordBreak: 'break-all'
        },
        centered: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
        },
        previewTable: {
            width: 'auto'
        },
        previewTableLoading: {
            width: '100%'
        },
        previewTableCellHeader: {
            backgroundColor: grey[200]
        },

        previewTableCellHeaderName: {
            fontWeight: 600
        },

        previewTableCell: {
            border: '2px',
            borderColor: grey[400],
            borderStyle: 'solid',
            padding: theme.spacing(1, 0.5),
            width: 170,
            minWidth: 170,
            maxWidth: 170,
            fontSize: '12px'
        },
        previewTableCellHeaderAction: {
            fontSize: 'medium'
        },
        previewHeaderDragging: {
            display: 'block'
        },
        loadingRow: {
            backgroundColor: 'transparent !important'
        },
        previewTableCellHeaderActionColumn: {
            padding: theme.spacing(0.5, 2)
        },
        cardTopActions: {
            justifyContent: 'flex-end',
            padding: theme.spacing(1, 2)
        },
        onDragStyle: {
            display: 'flex',
            flexDirection: 'row'
        },
        pagination: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        numericColumn: {
            textAlign: 'right'
        },
        tableContainer: {
            height: '100%',
            marginBottom: theme.spacing(1)
        }
    })
);

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
    ...((isDragging && {
        borderColor: 'transparent'
    }) || {}),
});

const getDataKey = (columnName: string): string => {
    const firstWord = columnName.trim().split(' ')[0];
    const splitFirst = firstWord.split('/');
    columnName = splitFirst[0].toLowerCase() + (splitFirst.length > 1 ? '/' + firstWord.substring(firstWord.indexOf('/') + 1) : '') +  (columnName.trim().split(' ').length !== 1 ? ' ' + columnName.substring(columnName.indexOf(" ") + 1) : '');
    columnName = columnName.charAt(0).toLowerCase() + columnName.slice(1);
    return columnName;
}

const NewAdhoc = (_props: any) => {
    const classes = useStyles({});
    const selectedColumns: Array<{ key: string; field: IAdhocReportField }> = useSelector(adHocDetailsSelectors.reportViewColumns);
    const selectedSorts: Array<IAdhocRerportColumnSortOption> = useSelector(adHocDetailsSelectors.sortOptions);
    const loading: boolean = useSelector(adHocDetailsSelectors.loadingDetails);
    const saving: boolean = useSelector(adHocDetailsSelectors.saving);
    const reportId: string = useSelector(adHocDetailsSelectors.reportId);
    const notification: CustomSnackBarProps = useSelector(adHocDetailsSelectors.notification);
    const loadingPreviewData: boolean = useSelector(adHocDetailsSelectors.loadingPreviewData);
    const report: IAdhocReportDetails = useSelector(adHocDetailsSelectors.detailsObject);
    const exporting: boolean = useSelector(adHocDetailsSelectors.epxorting);
    const previewData: {
        totalPages: number;
        totalRecords: number;
        records: Array<any>;
        currentPage: number;
        pageSize: number;
    } = useSelector(adHocDetailsSelectors.previewData);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const [sortOpen, setSortOpen] = React.useState(false);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [save, setSave] = React.useState(false);
    const actions = useActions({
        deleteColumn: adHocDetailsActions.toggleField,
        changeSortDirection: adHocDetailsActions.changeSortDirection,
        getDetails: adHocDetailsActions.getAdHocDetails,
        changeVisibility: adHocDetailsActions.toggleFieldVisibility,
        reOrder: adHocDetailsActions.updateColumnOrder,
        changePreviewPage: adHocDetailsActions.changePreviewPage,
        hideNotification: adHocDetailsActions.hideNotification,
        exportReport: adHocDetailsActions.exportAdHocReport,
    }, []);
    const location = useLocation();
    const history = useHistory();
    const locPaqrams = useParams<{ reportId: string }>();
    const displayActionsColumn = (m: IAdhocReportField, group: string) => {
        const sort = selectedSorts.find(s => s.fieldId === m.id);
        const sortIndex = selectedSorts.findIndex(s => s.fieldId === m.id);
        return (
            <TableCell key={`column-actions-${m.id}`} className={clsx(classes.previewTableCell, classes.previewTableCellHeader, classes.previewTableCellHeaderActionColumn)}
                variant="head"
                component="div"
            >
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <IconButton size="small" key={`column-actions-visiblity-${m.id}`} onClick={() => actions.changeVisibility(group, m.id)}>
                            {
                                (!m.visiblityOff &&
                                    <VisibilityIcon key={`column-actions-visiblity-icon-${m.id}`} className={classes.previewTableCellHeaderAction} />)
                                ||
                                <VisibilityOffIcon key={`column-actions-visiblity-icon-${m.id}`} className={classes.previewTableCellHeaderAction} />
                            }
                        </IconButton>
                        <IconButton size="small" key={`column-actions-delete-${m.id}`} onClick={() => actions.deleteColumn(group, m.id)}>
                            <DeleteIcon key={`column-actions-delete-icon-${m.id}`} className={classes.previewTableCellHeaderAction} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {

                            sort &&
                            <IconButton size="small" key={`column-actions-sort-button-${m.id}`} onClick={() => actions.changeSortDirection(sortIndex, sort.sort === AhHocReportColumnSortOption.Asc ? AhHocReportColumnSortOption.Desc : AhHocReportColumnSortOption.Asc, true)}>
                                {(sort.sort === AhHocReportColumnSortOption.Asc ? <AscIcon key={`column-actions-sort-${m.id}`} className={classes.previewTableCellHeaderAction} /> : <DescIcon key={`column-actions-sort-${m.id}`} className={classes.previewTableCellHeaderAction} />)}
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </TableCell>)
    }

    React.useEffect(() => {
        actions.hideNotification();
        if (location && location.pathname && location.pathname.endsWith('newAdhocReport')) {
            actions.getDetails(locPaqrams.reportId);
        }
        else if (locPaqrams && locPaqrams.reportId) {
            actions.getDetails(locPaqrams.reportId);
        }
    }, []);

    const [isDragging, setIsDragging] = React.useState(false);

    const onDragStart = () => {
        setIsDragging(true);
    }

    const onDragEnd = (result: DropResult) => {
        if (!result || !result.destination) {
            setIsDragging(false);
            return;
        }
        let start = 0;
        if (result.source.index === result.destination.index) {
            setIsDragging(false);
            return;
        }
        else if (result.source.index < result.destination.index) {
            start = result.source.index;
        }
        else {
            start = result.destination.index;
        }
        let sliced: Array<{ key: string; field: IAdhocReportField }> = [];
        if (result.source.index < result.destination.index) {
            sliced = [...selectedColumns.slice(result.source.index + 1, result.destination.index + 1), selectedColumns[result.source.index]];
        }
        else if (result.source.index > result.destination.index) {
            sliced = [selectedColumns[result.source.index], ...selectedColumns.slice(result.destination.index, result.source.index)];
        }
        const indexesToUpdate: Array<{ group: string; field: string; index: number }> = [];
        sliced.forEach((m, index) => {
            indexesToUpdate.push({ group: m.key, field: m.field.id, index: index + start });
        });
        actions.reOrder(indexesToUpdate);
        setIsDragging(false);
    }

    const transformCellValue = (value: any, type: any, prefix: string | undefined, postFix: string | undefined) => {
        return  `${(value && prefix) || ''} ${type === 'Date' && value ? (new Date(value)).toLocaleDateString()
        : type == "Boolean" ? (value == true ? 'Yes' : value == false ? 'No' : ''): type == "Number" ? (value) : (value || '')} ${(value && postFix) || ''}`
    }

    const renderValueCell = (value: any, key: string, prefix: string | undefined, postFix: string | undefined, type: string) => {
        return (<TableCell key={key}
            variant="body"
            component="div"
            className={clsx(classes.previewTableCell,
                type == 'Number' ? classes.numericColumn : undefined)}>
            {transformCellValue(value, type, prefix, postFix)}
        </TableCell>)
    }

    const [editorOpen, setEditorOpen] = React.useState(true);

    React.useEffect(() => {
        if (!saving && save) {
            setSave(false);
            if (location && location.pathname && location.pathname.endsWith('newAdhocReport')) {
                history.push(`/reports/adhoc/report/${reportId}`);
            }
        }
    }, [saving]);

    React.useEffect(() => {
        actions.changePreviewPage(1, 10);
    }, [userSelectedCampus]);

    const appSettings = useSelector((state: any) => {
		return state.settings.settings;
	});

    const newManageAdhocSetting = appSettings[
        system.settings.enableNewManageAdhocFlow
    ] as string;
	const getNewManageAdhocSetting = () => {
		
		switch (newManageAdhocSetting) {
			case 'New':
				return 1;
			case 'Existing':
				return 2;
			case 'Both':
				return 3;
			default:
				return 0;
		}
	};
    const adHocSettings = getNewManageAdhocSetting();
    if(!!appSettings && !!newManageAdhocSetting && adHocSettings !== 1 && adHocSettings !== 3 ){
        return <Redirect to={'/reports'} />
    }


    return (
        <React.Fragment>
            <Card className={classes.root}>
                <CardActions className={classes.cardTopActions}>
                    <Button variant="text" color="primary" startIcon={<FilterListIcon />} onClick={() => setFilterOpen(true)}>
                        Filter
                    </Button>
                    <Button variant="text" color="primary" startIcon={<SwapVertIcon />} onClick={() => setSortOpen(true)}>
                        Sort
                    </Button>
                    <Button variant="text" color="primary" startIcon={<TableChartOutlinedIcon />} onClick={() => setEditorOpen(state => !state)}>
                        Edit Columns
                    </Button>
                    {/* Not Implemented Yet
                    <Button variant="text" color="primary" startIcon={<ViewWeekOutlinedIcon />}>
                        Custom Columns
                    </Button>
                    <Button variant="text" color="primary" startIcon={<GroupWorkOutlinedIcon />}>
                        Group
                    </Button> 
                    Not Implemented Yet */}
                </CardActions>
                <CardContent className={classes.container}>
                    <Grid container spacing={2} className={classes.fullHeight}>
                        {editorOpen && <Grid item xs={5} sm={3} className={classes.fullHeight}>
                            <AdhocFieldViewer
                            />
                        </Grid>}
                        <Grid item xs={(editorOpen && 7) || 12} sm={(editorOpen && 9) || 12} container className={classes.fullHeight}>

                            <Grid item xs={12} className={classes.previewWindow}>
                                <Paper elevation={3} className={classes.dropArea}>

                                    <TableContainer className={classes.tableContainer}>
                                        <Table className={clsx(loading ? classes.previewTableLoading : classes.previewTable, !isDragging ? classes.fullHeight : undefined)}>
                                            {!loading && <TableHead>

                                                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                                                    <Droppable droppableId="report-columns" direction="horizontal">
                                                        {(provided, snapshot) => (
                                                            <TableRow
                                                                component="div"
                                                                ref={provided.innerRef}
                                                                className={snapshot.isDraggingOver ? classes.onDragStyle : undefined}
                                                                {...provided.droppableProps}>
                                                                {
                                                                    selectedColumns && selectedColumns.length > 0 &&
                                                                    selectedColumns.map((m, index) =>
                                                                        <Draggable key={`${m.field.id}-${m.key}`} draggableId={`${m.field.id}-${m.key}`} index={index}>
                                                                            {(providedDraG, snapshot) => (<TableCell
                                                                                ref={providedDraG.innerRef}
                                                                                {...providedDraG.draggableProps}
                                                                                {...providedDraG.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    providedDraG.draggableProps.style
                                                                                )} key={`column-name-${m.field.id}`} className={clsx(classes.previewTableCell, classes.previewTableCellHeader, classes.previewTableCellHeaderName, isDragging ? classes.previewHeaderDragging : undefined)}
                                                                                variant="head"
                                                                                component="div"
                                                                            >
                                                                                {m.field.name}
                                                                            </TableCell>)}
                                                                        </Draggable>
                                                                    )}
                                                                {provided.placeholder}
                                                            </TableRow>)}
                                                    </Droppable>
                                                </DragDropContext>
                                                {!isDragging && <TableRow component="div">
                                                    {
                                                        selectedColumns && selectedColumns.length > 0 &&
                                                        selectedColumns.map(m => displayActionsColumn(m.field, m.key))
                                                    }

                                                </TableRow>}
                                            </TableHead>}
                                            {!isDragging && ((loadingPreviewData && <TableBody>
                                                {((selectedColumns && selectedColumns.length > 0) || loading) && <TableRow hover={true} className={loading ? classes.loadingRow : undefined}>
                                                    <TableCell variant="body"

                                                        align="center"
                                                        colSpan={selectedColumns.length}
                                                        className={classes.previewTableCell}>
                                                        <CircularProgress color="primary" />
                                                    </TableCell>
                                                </TableRow>}
                                            </TableBody>)
                                                ||
                                                <TableBody component="div">
                                                    {
                                                        previewData.records && previewData.records.map((item, index) => (
                                                            <TableRow hover={true} component="div" key={`data-row-${index}`}>
                                                                {
                                                                    selectedColumns && selectedColumns.length > 0 &&
                                                                    selectedColumns.map(m => {
                                                                        return renderValueCell(item[getDataKey(m.field.name)], `data-column-${m.field.id}-${index}`, m.field.prefix, m.field.postfix, m.field.fieldType);
                                                                    })
                                                                }
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                    {!isDragging && previewData.totalPages > 0 && <Pagination className={classes.pagination} onChange={(_e, page) => actions.changePreviewPage(page)} color="primary" variant="text" disabled={loadingPreviewData} count={previewData.totalPages} page={previewData.currentPage} />}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <ExportButton progress={exporting} exportFunction={(exportType) => actions.exportReport(userSelectedCampus, report.reportOptions, report.reportName, exportType)} />
                    <Button variant="contained" color="primary" onClick={() => setSave(true)}>Save</Button>
                    <Button variant="contained" color="primary" onClick={() => history.push('/reports/adhoc/')}>{ (locPaqrams && locPaqrams.reportId &&'Back to List') || 'Cancel' }</Button>
                </CardActions>
            </Card>
            <AdHocReportSortConfiguration open={sortOpen} close={() => setSortOpen(false)} />
            <AdHocReportFilterConfiguration open={filterOpen} close={() => setFilterOpen(false)} />
            <SaveAdHocReportPopUp open={save} close={() => setSave(false)} />
            {notification && <CustomSnackbar open={notification.showSnackBar} message={notification.messageInfo} variant={notification.variant} onClose={actions.hideNotification} />}
        </React.Fragment>
    );
};

export default NewAdhoc;
