import React, { useEffect } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CostOfAttendance } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { useFormContext, Controller } from "react-hook-form";
import * as EnrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { useSelector } from "react-redux";
import { ActionResult } from "../../../../interfaces/common/api/ActionResult";
import AwardYearAutoComplete from "../../../../components/AutoComplete/AwardYearAutoComplete";
import { system } from "../../../../constants/system/systemConstants";
import { useForm, FormContext } from "react-hook-form";
import * as enrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%",
      },
    },
  })
)(TextField);

type AttendDetailsProps = {
  attendDetails: CostOfAttendance;
  setAttend: any;
  isNewEnrollmentTransfer: boolean;
  programVersionId?: string;
  isNewEnrollment?: boolean;
  setIsAttendStudent?: any;
  isNewStudentWorkflow?: boolean;
};

const CostOfAttend = (props: AttendDetailsProps) => {
  const methods = useForm<any>({
    mode: "onBlur",
  });
  //const { register, handleSubmit } = methods;

  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  //--------VERSION 1

  // //Yes/No
  // const getAfaIntegratedFlag = () => {
  //   const afaIntegrationSetting = appSettings[
  //     system.settings.enableAFAIntegration
  //   ] as string;

  //   let afaIntegrationEnabled = false;

  //   if (afaIntegrationSetting) {
  //     afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === "yes";
  //   }

  //   return afaIntegrationEnabled;
  // };

  // //True/False
  // const getOverrideEnabledDeleteFlag = () => {
  //   const allowAFAOverrideSetting = appSettings[
  //     system.settings.allowAFAOverride
  //   ] as string;
  //   let allowAFAOverrideEnabled = false;

  //   if (allowAFAOverrideSetting) {
  //     allowAFAOverrideEnabled = allowAFAOverrideSetting.toLowerCase() === "true";

  //   }

  //   return allowAFAOverrideEnabled;
  // };

  // const isAfaIntegrationEnabled = getAfaIntegratedFlag();
  // const allowAFAOverrideEnabledDelete = getOverrideEnabledDeleteFlag();

  //----Verion 2

  const getAfaIntegratedFlag = () => {
    const afaIntegrationSetting = appSettings[
      system.settings.enableAFAIntegration
    ] as string;
    let afaIntegrationEnabled = false;

    const allowAFAOverrideSetting = appSettings[
      system.settings.allowAFAOverride
    ] as string;
    let allowAFAOverrideEnabled = false;

    if (afaIntegrationSetting) {
      afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === "yes";
      if (afaIntegrationEnabled)
        if (allowAFAOverrideSetting) {
          allowAFAOverrideEnabled =
            allowAFAOverrideSetting.toLowerCase() === "true";

          if (allowAFAOverrideEnabled) {
            afaIntegrationEnabled = false;
          }
        }
    }

    return afaIntegrationEnabled;
  };

  const isAfaIntegrationEnabled = getAfaIntegratedFlag();
  const classes = useStyles({});
  const data = useFormContext();
  const { attendDetails, setAttend } = props;
  const studentId = useSelector<any, any>(
    (state: any) => state.student.studentId
  );

  interface FilmOptionType {
    text: string;
    id: string;
  }
  const defaultProps = {
    options: [],
    getOptionLabel: (option: FilmOptionType) => option.text,
  };

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const GetAttendDetails = () => {
    if (
      props.isNewEnrollment !== undefined &&
      props.isNewEnrollment === false &&
      props.programVersionId
    ) {
      EnrollmentApi.getCostOfAttendance(studentId, props.programVersionId).then(
        (response: ActionResult) => {
          if (response.resultStatus === 0) {
            if (response.result !== undefined && response.result !== null) {
              let updateAttendDetails = attendDetails;
              let data: any = response.result;
              updateAttendDetails.totalCostField1 = data.totalCostField1;
              updateAttendDetails.awardYearField1 = data.awardYearField1;
              updateAttendDetails.totalCostField2 = data.totalCostField2;
              updateAttendDetails.awardYearField2 = data.awardYearField2;
              updateAttendDetails.totalCostField3 = data.totalCostField3;
              updateAttendDetails.awardYearField3 = data.awardYearField3;
              updateAttendDetails.totalCostField4 = data.totalCostField4;
              updateAttendDetails.awardYearField4 = data.awardYearField4;
              updateAttendDetails.StuEnrollId = data.StuEnrollId;
              updateAttendDetails.ProgramVersionId = data.ProgramVersionId;
              setAttend({ ...updateAttendDetails });
              if (props.setIsAttendStudent) {
                props.setIsAttendStudent(true);
              }
            }
          }
        }
      );
    }
  };
  useEffect(() => {
    GetAttendDetails();
  }, []);

  useEffect(() => {
    GetAttendDetails();
  }, [props.programVersionId]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = attendDetails;
    if(selectedEnrollment!==undefined){
      {
      updatedModel.ProgramVersionId = selectedEnrollment.stuEnrollId;
      updatedModel.StuEnrollId = selectedEnrollment.programVersionId;

      } 

    }



    (updatedModel as any)[fieldId] = value;

    if (fieldId == "transferHoursOther" && !value) {
      // updatedModel.transferHoursOther = 0;
    }
    setAttend({ ...updatedModel });
  };


  const handleSubmit = () =>
    {
      if (attendDetails) {
        setLoaderState(true);


        if (
          attendDetails.awardYearField1 === attendDetails.awardYearField2 ||
          attendDetails.awardYearField1 === attendDetails.awardYearField3 ||
          attendDetails.awardYearField1 === attendDetails.awardYearField4 ||
          attendDetails.awardYearField2 === attendDetails.awardYearField3 ||
          attendDetails.awardYearField2 === attendDetails.awardYearField4 ||
          attendDetails.awardYearField3 === attendDetails.awardYearField4
        ) {
          console.log("Fail, one or more award year same as another");
          setLoaderState(false);
        } else {
          console.log("Pass, all award years are unique");
          onSubmit();
        }
      }
    };

  const onSubmit = () => {
    console.log("Test");
    enrollmentApi.updateCostOfAttendance(attendDetails).then(
      (response: ActionResult) => {
        let data: any = response.result;
        if (response.resultStatus === 0) {
          if (response.result !== undefined && response.result !== null) {
            console.log("success");
            console.log(response);
            console.log("response.data");
            console.log(data);
            setSnackBarProps((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: "Cost of Attendance successfully updated.",
              };
            });
            setLoaderState(false);
          }
        } else {
          console.log("failure");
          console.log(response);
          console.log("response.data");
          console.log(data);

          setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Cost of Attendance updates could not be completed. Please try again.",
            };
          });
          setLoaderState(false);
        }
      }

    );
  };

  return (
    <Card square={true}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Typography className={clsx(classes.cardTitle)}>
        &nbsp; Cost of Attendance
      </Typography>
      <CardContent className={classes.cardContent}>
        <Grid container direction="row" spacing={2}></Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <h5></h5>
          </Grid>
        </Grid>

        {/* ------------Row 1----------- */}

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={2}>
            <AwardYearAutoComplete
              id="AwardYearField1"
              name="AwardYearField1"
              label="Award Year"
              disabled={isAfaIntegrationEnabled}
              //selectAll={true}
              valueFilter={
                attendDetails.awardYearField1
                  ? {
                      key: "value",
                      values: [attendDetails.awardYearField1],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("awardYearField1", v ? v.value : undefined);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemeTextField
              id="TotalCostField1"
              className={classes.textField}
              label="total Cost"
              disabled={isAfaIntegrationEnabled}
              type="text"
              value={attendDetails.totalCostField1}
              onChange={(e: any) => {
                handleFieldOnChange("totalCostField1", e.target.value);
              }}
            />
          </Grid>

          {/* ------------------- */}

          <Grid item xs={12} sm={2}>
            <AwardYearAutoComplete
              id="AwardYearField2"
              name="AwardYearField2"
              label="Award Year"
              disabled={isAfaIntegrationEnabled}
              //selectAll={true}
              valueFilter={
                attendDetails.awardYearField2
                  ? {
                      key: "value",
                      values: [attendDetails.awardYearField2],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("awardYearField2", v ? v.value : undefined);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemeTextField
              id="TotalCostField2"
              className={classes.textField}
              label="total Cost"
              disabled={isAfaIntegrationEnabled}
              type="text"
              value={attendDetails.totalCostField2}
              onChange={(e: any) => {
                handleFieldOnChange("totalCostField2", e.target.value);
              }}
            />
          </Grid>
        </Grid>

        {/* ------------Row 2----------- */}

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={2}>
            <AwardYearAutoComplete
              id="AwardYearField3"
              name="AwardYearField3"
              label="Award Year"
              disabled={isAfaIntegrationEnabled}
              //selectAll={true}
              valueFilter={
                attendDetails.awardYearField3
                  ? {
                      key: "value",
                      values: [attendDetails.awardYearField3],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("awardYearField3", v ? v.value : undefined);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemeTextField
              id="TotalCostField3"
              className={classes.textField}
              label="total Cost"
              disabled={isAfaIntegrationEnabled}
              type="text"
              value={attendDetails.totalCostField3}
              onChange={(e: any) => {
                handleFieldOnChange("totalCostField3", e.target.value);
              }}
            />
          </Grid>

          {/* ------------------- */}

          <Grid item xs={12} sm={2}>
            <AwardYearAutoComplete
              id="AwardYearField4"
              name="AwardYearField4"
              label="Award Year"
              disabled={isAfaIntegrationEnabled}
              //selectAll={true}
              valueFilter={
                attendDetails.awardYearField4
                  ? {
                      key: "value",
                      values: [attendDetails.awardYearField4],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("awardYearField4", v ? v.value : undefined);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemeTextField
              id="TotalCostField4"
              className={classes.textField}
              label="total Cost"
              disabled={isAfaIntegrationEnabled}
              type="text"
              value={attendDetails.totalCostField4}
              onChange={(e: any) => {
                handleFieldOnChange("totalCostField4", e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <h5></h5>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <h5></h5>
          </Grid>
        </Grid>

        <Grid
          item
          alignContent="flex-start"
          alignItems="flex-start"
          justify="flex-start"
        >
          <Button

            onClick={() => {
              handleSubmit();
            }}
            disabled={isAfaIntegrationEnabled}
            size="small"
            color="primary"
            variant="contained"
            type="button"
          >
            NEW
          </Button>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default CostOfAttend;
