import { createReducer } from '../utils';
import { IAdHocReportOption, IAdhocRerportColumnSortOption, IAdhocRerportColumsObject, IAdhocRerportFilterOption } from '../../interfaces/reports/adhoc/IAdHocReportOptions';
import { IAdhocReportField } from '../../interfaces/reports/adhoc/IAdhocReportField';
import { IAhHocReportFieldGroup } from '../../interfaces/reports/adhoc/IAhHocReportFieldGroup';
import { AhHocReportColumnSortOption } from '../../enums/AhHocReportColumnSortOption';
import { AdHocReportFilterOperation, CombineFilter } from '../../enums/AdHocReportFilterOperation';
import { IAdhocReportDetails } from '../../interfaces/reports/adhoc/IAdhocReportDetails';
import { EmptyGuid } from '../../utils/constants';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';

const mapField = (m: IAhHocReportFieldGroup, search: string): IAhHocReportFieldGroup => ({ ...m, fields: m.fields.filter(f => f.id.toLowerCase().includes(search) || f.name.toLowerCase().includes(search)) });

const { reducer, actions, selectors, types } = createReducer({
    sync: {
        hideNotification: () => undefined,
        toggleField: (group: string, key: string) => ({ group, key }),
        filterColumns: (search: string) => search,
        toggleAllFields: (include: boolean = false) => include,
        addSortOption: (field?: string, sort?: AhHocReportColumnSortOption) => ({ field, sort }),
        addFilterOption: (field?: string, filter?: AdHocReportFilterOperation, valueStart?: any, valueEnd: any = undefined, combineAs: CombineFilter | undefined = undefined) => ({ field, filter, valueStart, valueEnd, combineAs }),
        deleteSortingOption: (index: number) => index,
        deleteFilterOption: (index: number) => index,
        deleteAllSorts: () => undefined,
        deleteAllFilters: () => undefined,
        setFiltersForEdit: () => undefined,
        setSortForEdit: () => undefined,
        applyFilters: () => undefined,
        applySort: () => undefined,
        changeSortDirection: (index: number, sort: AhHocReportColumnSortOption, apply: boolean = false) => ({ index, sort, apply }),
        changeSortField: (index: number, field?: string) => ({ index, field }),
        updateFilter: (index: number, filter: IAdhocRerportFilterOption) => ({ index, filter }),
        toggleFieldVisibility: (group: string, key: string) => ({ group, key }),
        updateColumnOrder: (columns: Array<{ group: string; field: string; index: number }>) => columns,
        changePreviewPage: (page: number, pageSize: number = 10) => ({ page, pageSize }),
        modifyReportDetails: (field: keyof {
            reportName?: string;
            categoryId?: string;
            reportDescription?: string;
            isRestricted?: boolean;
            accessibleByUserIds?: Array<string>;
            reportCampusIds?: Array<string>;

        }, value: any) => ({ field, value }),
        clearBlob: () => undefined
    },
    asyncs: {
        getAdHocDetails: {
            request: (reportId: string = EmptyGuid) => ({ reportId }),
            success: (details: IAdhocReportDetails) => details,
            failure: (message: string) => message
        },
        loadPreviewData: {
            request: (pageNumber: number, pageSize: number, campusId: string, options: IAdHocReportOption) => ({ pageNumber, pageSize, campusId, options }),
            success: (data: {
                totalPages: number;
                totalRecords: number;
                records: Array<any>;
                currentPage: number;
                pageSize: number;
            }) => data,
            failure: (message: string) => message,
        },
        saveAdHocReport: {
            request: (details: IAdhocReportDetails) => details,
            success: (details: IAdhocReportDetails) => details,
            failure: (message: string) => message
        },
        exportAdHocReportById: {
            request: (reportId: string, campusId: string, fileName: string, exportType?: number) => ({ reportId, campusId, fileName, exportType }),
            success: (message: string) => message,
            failure: (message: string) => message
        },
        exportAdHocReport: {
            request: (campusId: string, options: IAdHocReportOption, reportName?: string, exportType?: number) => ({ reportName, campusId, options, exportType }),
            success: (message: string) => message,
            failure: (message: string) => message
        },
        deleteAdHocReport: {
            request: (reportId) => reportId,
            success: (message: CustomSnackBarProps) => message,
            failure: (message: CustomSnackBarProps) => message
        },
        duplicateAdHocReport: {
            request: (reportId) => reportId,
            success: (message: CustomSnackBarProps) => message,
            failure: (message: CustomSnackBarProps) => message
        },
        getPreviewBlobAdHocReportById: {
            request: (reportId: string, campusId: string, fileName: string, exportType?: number) => ({ reportId, campusId, fileName, exportType }),
            success: (data: Blob) => data,
            failure: (message: string) => message
        },
    }
}, {
    previewBlob: undefined as (Blob | undefined),
    notification: undefined as (CustomSnackBarProps | undefined),
    filterColumns: "",
    filterEdit: [] as Array<IAdhocRerportFilterOption>,
    sortEdit: [] as Array<IAdhocRerportColumnSortOption>,
    message: '',
    reportId: EmptyGuid as (string | undefined),
    reportName: undefined as (string | undefined),
    categoryId: undefined as (string | undefined),
    reportDescription: undefined as (string | undefined),
    isRestricted: false as (boolean | undefined),
    accessibleByUserIds: undefined as (Array<string> | undefined),
    reportCampusIds: undefined as (Array<string> | undefined),
    options: {
        columns: {

        },
        sortingOptiopns: [],
        filters: []
    } as IAdHocReportOption,
    previewData: {
        totalPages: 0,
        totalRecords: 0,
        records: [] as Array<any>,
        currentPage: 1,
        pageSize: 10
    }
}, {
    sync: {
        clearBlob: (state, _action) => ({ ...state, data: { ...state.data, previewBlob: undefined } }),
        hideNotification: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
        modifyReportDetails: (state, action) => ({ ...state, data: { ...state.data, [action.data.field]: action.data.value } }),
        changePreviewPage: (state, action) => ({ ...state, data: { ...state.data, previewData: { ...state.data.previewData, currentPage: action.data.page, pageSize: action.data.pageSize } } }),
        updateColumnOrder: (state, action) => {
            action.data.forEach(m => {
                const foundIndex = state.data.options.columns[m.group].fields.findIndex(f => f.id === m.field);
                if (foundIndex > -1) {
                    (state.data.options.columns[m.group].fields[foundIndex] as IAdhocReportField).dataDisplayOrder = m.index;
                    state.data.options.columns[m.group].fields = [...state.data.options.columns[m.group].fields];
                }
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        columns: {
                            ...state.data.options.columns
                        }
                    }
                }
            };
        },
        updateFilter: (state, action) => {
            const isNew = !state.data.filterEdit[action.data.index].combineAs;
            state.data.filterEdit.splice(action.data.index, 1, { ...action.data.filter, combineAs: isNew ? CombineFilter.and : action.data.filter.combineAs });
            return {
                ...state,
                data: {
                    ...state.data,
                    filterEdit: [...state.data.filterEdit]
                }
            };
        },
        addFilterOption: (state, action) => {
            state.data.filterEdit.push({ fieldId: action.data.field, type: action.data.filter, valueStart: action.data.valueStart, valueEnd: action.data.valueEnd, combineAs: action.data.combineAs });
            return {
                ...state,
                data: {
                    ...state.data,
                    filterEdit: [...state.data.filterEdit]
                }
            };
        },
        deleteFilterOption: (state, action) => {
            state.data.filterEdit.splice(action.data, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    filterEdit: [...state.data.filterEdit]
                }
            };
        },
        setFiltersForEdit: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    filterEdit: [...state.data.options.filters]
                }
            };
        },
        setSortForEdit: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: [...state.data.options.sortingOptiopns]
                }
            };
        },
        applyFilters: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        filters: [...state.data.filterEdit.filter(m => !!m.combineAs)]
                    }
                }
            };
        },
        deleteAllFilters: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    filterEdit: []
                }
            };
        },
        applySort: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        sortingOptiopns: [...state.data.sortEdit.filter(m => !!m.fieldId)]
                    }
                }
            };
        },
        deleteAllSorts: (state, _action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: []
                }
            };
        },
        changeSortDirection: (state, action) => {
            state.data.sortEdit.splice(action.data.index, 1, { ...state.data.sortEdit[action.data.index], sort: action.data.sort });
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: [...state.data.sortEdit]
                }
            };
        },
        changeSortField: (state, action) => {
            state.data.sortEdit.splice(action.data.index, 1, { ...state.data.sortEdit[action.data.index], fieldId: action.data.field });
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: [...state.data.sortEdit]
                }
            };
        },
        deleteSortingOption: (state, action) => {
            state.data.sortEdit.splice(action.data, 1);
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: [...state.data.sortEdit]
                }
            };
        },
        addSortOption: (state, action) => {
            state.data.sortEdit.push({ fieldId: action.data.field, sort: action.data.sort });
            return {
                ...state,
                data: {
                    ...state.data,
                    sortEdit: [...state.data.sortEdit]
                }
            };
        },
        toggleAllFields: (state, action) => {
            const filter = state.data.filterColumns;
            let addedFields = 1;
            const newColumns: IAdhocRerportColumsObject = Object.values(state.data.options.columns).reduce((acc, curr, index) => {
                return {
                    ...acc,
                    [curr.id]: {
                        ...curr,
                        fields: (curr.fields).map((m) => {
                            const include = (action.data && filter && !(m.id.toLowerCase().includes(filter) || m.name.toLowerCase().includes(filter))) ? m.included : action.data;
                            const order = m.included ? m.dataDisplayOrder : Math.max(...[...Object.values(state.data.options.columns).map(m => Math.max(...m.fields.map(f => ((f.dataDisplayOrder || f.dataDisplayOrder === 0) ? f.dataDisplayOrder : -1))))]) + addedFields;
                            if(include && !m.included){
                                addedFields++;
                            }
                            return {
                                ...m,
                                included: include,
                                dataDisplayOrder: include ? order : undefined
                            }
                        })
                    }
                }
            }, {});
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        columns: {
                            ...newColumns
                        },
                        sortingOptiopns: (action.data ? state.data.options.sortingOptiopns : []),
                        filters: (action.data ? state.data.options.filters : [])
                    }
                }
            };
        },
        toggleFieldVisibility: (state, action) => {
            const foundIndex = state.data.options.columns[action.data.group].fields.findIndex(m => m.id === action.data.key);
            if (foundIndex > -1) {
                (state.data.options.columns[action.data.group].fields[foundIndex] as IAdhocReportField).visiblityOff = !((state.data.options.columns[action.data.group].fields[foundIndex] as IAdhocReportField).visiblityOff);


                return {
                    ...state,
                    data: {
                        ...state.data,
                        options: {
                            ...state.data.options,
                            columns: {
                                ...state.data.options.columns,
                                [action.data.group]: {
                                    ...state.data.options.columns[action.data.group],
                                    fields: [...state.data.options.columns[action.data.group].fields]
                                }
                            },
                            sortingOptiopns: [
                                ...state.data.options.sortingOptiopns
                            ]
                        }
                    }
                };
            }
            return state;
        },
        toggleField: (state, action) => {
            const foundIndex = state.data.options.columns[action.data.group].fields.findIndex(m => m.id === action.data.key);
            if (foundIndex > -1) {
                (state.data.options.columns[action.data.group].fields[foundIndex] as IAdhocReportField).included = !((state.data.options.columns[action.data.group].fields[foundIndex] as IAdhocReportField).included);
                const sortingIndex = state.data.options.sortingOptiopns.findIndex(m => m.fieldId === action.data.key);
                const filteringIndex = state.data.options.filters.findIndex(m => m.fieldId === action.data.key);
                if (!((state.data.options.columns[action.data.group].fields[foundIndex] as IAdhocReportField).included)) {
                    state.data.options.columns[action.data.group].fields[foundIndex].dataDisplayOrder = undefined;
                    if (sortingIndex > -1) {
                        state.data.options.sortingOptiopns.splice(sortingIndex, 1);
                    }
                    if (filteringIndex > -1) {
                        state.data.options.filters.splice(sortingIndex, 1);
                    }
                }
                else {
                    state.data.options.columns[action.data.group].fields[foundIndex].dataDisplayOrder = Math.max(...[...Object.values(state.data.options.columns).map(m => Math.max(...m.fields.map(f => ((f.dataDisplayOrder || f.dataDisplayOrder === 0) ? f.dataDisplayOrder : -1))))]) + 1;
                }
                return {
                    ...state,
                    data: {
                        ...state.data,
                        options: {
                            ...state.data.options,
                            columns: {
                                ...state.data.options.columns,
                                [action.data.group]: {
                                    ...state.data.options.columns[action.data.group],
                                    fields: [...state.data.options.columns[action.data.group].fields]
                                }
                            },
                            sortingOptiopns: [
                                ...state.data.options.sortingOptiopns
                            ],
                            filters: [
                                ...state.data.options.filters
                            ]
                        }
                    }
                };
            }
            return state;
        },
        filterColumns: (state, actions) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    filterColumns: actions.data
                }
            }
        }
    },
    asyncs: {
        saveAdHocReport: {
            request: (state, _action) => state,
            success: (state, action) => ({ ...state, data: { ...state.data, reportId: action.data.id, notification: { showSnackBar: true, messageInfo: 'Report saved successfully.', variant: 'success' as 'success' } } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'error' as 'error' } } })
        },
        getAdHocDetails: {
            request: (state, action) => ({
                ...state, data: {
                    ...state.data, previewData: {
                        totalPages: 0,
                        totalRecords: 0,
                        records: [] as Array<any>,
                        currentPage: 1,
                        pageSize: 10
                    }, reportId: action.data.reportId, message: ''
                }
            }),
            success: (state, action) => ({
                ...state, data: {
                    ...state.data,
                    options: { ...action.data.reportOptions },
                    reportId: action.data.id,
                    reportName: action.data.reportName,
                    categoryId: action.data.categoryId,
                    reportDescription: action.data.reportDescription,
                    isRestricted: action.data.isRestricted,
                    accessibleByUserIds: action.data.accessibleByUserIds,
                    reportCampusIds: action.data.reportCampusIds
                }
            }),
            failure: (state, action) => ({
                ...state, data: {
                    ...state.data, previewData: {
                        totalPages: 0,
                        totalRecords: 0,
                        records: [] as Array<any>,
                        currentPage: 1,
                        pageSize: 10
                    }, message: action.data,
                    notification: { showSnackBar: true, messageInfo: action.data, variant: 'error' as 'error' }
                }
            })
        },
        loadPreviewData: {
            request: (state, _action) => state,
            success: (state, action) => ({ ...state, data: { ...state.data, previewData: { ...action.data } } }),
            failure: (state, action) => ({
                ...state, data: {
                    ...state.data, previewData: {
                        totalPages: 0,
                        totalRecords: 0,
                        records: [] as Array<any>,
                        currentPage: 1,
                        pageSize: 10
                    }, message: action.data
                }
            })
        },
        exportAdHocReport: {
            request: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
            success: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'success' as 'success' } } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'error' as 'error' } } })
        },
        exportAdHocReportById: {
            request: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
            success: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'success' as 'success' } } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'error' as 'error' } } })
        },
        deleteAdHocReport: {
            request: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
            success: (state, action) => ({ ...state, data: { ...state.data, notification: action.data } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: action.data } })
        },
        duplicateAdHocReport: {
            request: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
            success: (state, action) => ({ ...state, data: { ...state.data, notification: action.data } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: action.data } })
        },
        getPreviewBlobAdHocReportById: {
            request: (state, _action) => ({ ...state, data: { ...state.data, notification: undefined } }),
            success: (state, action) => ({ ...state, data: { ...state.data, previewBlob: action.data } }),
            failure: (state, action) => ({ ...state, data: { ...state.data, notification: { showSnackBar: true, messageInfo: action.data, variant: 'error' as 'error' } } })
        }
    }
}, 'adhocReportDetails',
    {
        appliedFilter: (state) => state.data.filterColumns,
        reportId: (state) => state.data.reportId,
        options: (state) => state.data.options,
        reportColumns: (state) => {
            if (!state.data.filterColumns) {
                return state.data.options.columns;
            }

            const filteredFields = Object.values(state.data.options.columns).map(m => mapField(m, state.data.filterColumns.toLowerCase()));
            return filteredFields.filter(m => m.id.toLowerCase().startsWith(state.data.filterColumns.toLowerCase()) || m.name.toLowerCase().startsWith(state.data.filterColumns.toLowerCase()) || m.fields.length > 0).reduce((acc, curr) => ({ ...acc, [curr.id]: ({ ...curr }) }), {});
        },
        selectedColumns: (state): Array<IAdhocReportField> => {
            const columnsToReturn: Array<IAdhocReportField> = [];
            Object.values(state.data.options.columns).forEach(m => columnsToReturn.push(...m.fields.filter(m => m.included)));
            return columnsToReturn;
        },
        reportViewColumns: (state): Array<{ key: string; field: IAdhocReportField }> => {
            const columnsToReturn: Array<{ key: string; field: IAdhocReportField }> = [];
            Object.values(state.data.options.columns).forEach(m => columnsToReturn.push(...m.fields.filter(m => m.included).map(f => ({ field: f, key: m.id }))));
            return columnsToReturn.sort((a, b) => ((a.field.dataDisplayOrder || a.field.dataDisplayOrder === 0) && (b.field.dataDisplayOrder || b.field.dataDisplayOrder === 0) && (a.field.dataDisplayOrder > b.field.dataDisplayOrder ? 1 : b.field.dataDisplayOrder > a.field.dataDisplayOrder ? -1 : 0)) || 0);
        },
        sortOptions: (state) => state.data.options.sortingOptiopns,
        sortToEdit: (state) => state.data.sortEdit,
        filtersToEdit: (state) => {
            const columnsIncluded: Array<IAdhocReportField> = [];
            Object.values(state.data.options.columns).forEach(m => columnsIncluded.push(...m.fields.filter(m => m.included)));
            return state.data.filterEdit.map(m => {
                const fld = columnsIncluded.find(f => f.id === m.fieldId);
                return ({ filterItem: m, allowed: ((fld && fld.allowedFilters?.split(',').map(op => Number(op) as AdHocReportFilterOperation)) || []) })
            });
        },
        loadingDetails: (state) => state.loading.getAdHocDetails,
        saving: (state) => state.loading.saveAdHocReport,
        notification: (state) => state.data.notification,
        previewData: (state) => state.data.previewData,
        loadingPreviewData: (state) => state.loading.loadPreviewData,
        detailsObject: (state): IAdhocReportDetails => ({
            id: state.data.reportId,
            reportName: state.data.reportName,
            reportDescription: state.data.reportDescription,
            reportCampusIds: state.data.reportCampusIds,
            isRestricted: state.data.isRestricted,
            accessibleByUserIds: state.data.accessibleByUserIds,
            reportOptions: state.data.options,
            categoryId: state.data.categoryId
        }),
        previewBlob: (state) => state.data.previewBlob,
        epxorting: (state): boolean => (state.loading.exportAdHocReport || false),
        epxortingById: (state): boolean => (state.loading.exportAdHocReportById || false),
        loadingPreviewById: (state): boolean => (state.loading.getPreviewBlobAdHocReportById || false),
        deleting: (state): boolean => (state.loading.deleteAdHocReport || false),
        generatDetails: (state) => {
            const {
                reportName,
                categoryId,
                reportDescription,
                isRestricted,
                accessibleByUserIds,
                reportCampusIds
            } = state.data;
            return {
                reportName,
                categoryId,
                reportDescription,
                isRestricted,
                accessibleByUserIds,
                reportCampusIds
            }
        }
    });

export { selectors, actions, types };

export default reducer;