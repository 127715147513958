import API from "../../apiWrapper";
export const GetEnrollemntQuickInformation = (studentEnrollmentId: string) => {
  return API()
    .get("/AcademicRecords/Enrollment/GetQuickInformationForEnrollment", {
      params: {
        studentEnrollmentId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetAttendanceSummaryByEnrollment = (enrollmentId: string, startDate: Date, endDate: Date) => {
  return API()
    .get("/Attendance/AttendanceSummary/GetAttendanceSummaryByEnrollment", {
      params: {
        enrollmentId,
        startDate,
        endDate,
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const PrintAttendanceSummary = (enrollmentId: string, startDate: Date,  endDate: Date, campusId: string) => {  
  return API()
  .get("/Attendance/AttendanceSummary/PrintAttendenceSummaryQuickInfo", {
    params: {
      enrollmentId,
      startDate,
      endDate,
      campusId,      
    },
    responseType: "blob",
  })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "AttendanceSummary.pdf");
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetProgramPaymentPeriodQuickInformation = (
  studentEnrollmentId: string
) => {
  return API()
    .get(
      "/AcademicRecords/PaymentPeriod/GetQuickInformationForProgramPaymentPeriod",
      {
        params: {
          studentEnrollmentId
        }
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetFinancialAidQuickInformation = (
  studentEnrollmentId: string
) => {
  return API()
    .get(
      "/FinancialAid/StudentAward/GetQuickFinancialAidInformationForEnrollment",
      {
        params: {
          studentEnrollmentId
        }
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetTransactionsQuickInformation = (
  studentEnrollmentId: string
) => {
  return API()
    .get(
      "/StudentAccounts/Transaction/GetQuickTransactionInformationForEnrollment",
      {
        params: {
          studentEnrollmentId
        }
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetAttendanceQuickInformation = (enrollmentId: string) => {
  return API()
    .get("/AcademicRecords/StudentSummary/GetAttendanceSummary", {
      params: {
        enrollmentId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetPaymentPlanQuickInformation = (studentEnrollmentId: string) => {
	return API()
		.get(
			"/StudentAccounts/PaymentPlan/GetQuickPaymentPlanInformationForEnrollment",
			{
				params: {
					studentEnrollmentId
				}
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};
