import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useRef } from "react";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { FilterParams } from "../../interfaces/common/FilterParams";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "periodType",
	cacheTime: 5,
	route: "/StudentAccounts/Ledger/GetPeriodTypes",
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
	
};

export interface PeriodTypeProps extends FilterParams {
	className?: any;
	useSelectedValue?:boolean;
}

const PeriodTypeAutoComplete = (props: PeriodTypeProps ) => {
	const classes = useStyles({});
	const filterHandle = props.filterHandle;
	const inputRef: any = useRef(null);

	const handleOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	//if default value must be selected
	if ( props.useSelectedValue === undefined || (props.useSelectedValue && props.useSelectedValue === true)){
		config.optionsMappedFx =  (data: Array<any>) => {
			return	data.map((d) => {d.defaultSelected = d.text == "Payment Period";
				return d;
			})
		}
	}
	return (
		<ApiAutoComplete
			id="periodType"
			label={props.label ? props.label : "Period Type"}
			config={config}
			loading={false}
			className={props.className}
			onChange={(e: any) => {
				handleOnChange(e);
			}}
			multiple={false}
			valueFilter={props.valueFilter ? props.valueFilter : undefined}
			inputRef={props.inputRef ? props.inputRef : inputRef}
			error={props.error}
			helperText={props.helperText}
			inputName={props.name}
		/>
	);
};
export default PeriodTypeAutoComplete;
