import API from "../../apiWrapper";
import { INewNotes } from "../../../interfaces/student/notes/INotesInfo";

export const getStudentNotes = (leadId: string | undefined, moduleId: number | null) => {
  return API()
    .get("/LeadNotes/GetLeadNotes", {
      params: {
        leadId,
        moduleId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetNotesById = (notesId: number) => {
  return API()
    .get("/LeadNotes/GetNotesById", {
      params: {
        notesId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const CreateNotes = (model: INewNotes) => {
  return API()
    .post("/LeadNotes/CreateNotes", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const UpdateNotes = (model: INewNotes) => {
  return API()
    .put("/LeadNotes/UpdateNotes", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const ArchiveNotesById = (notesId: number) => {
  return API()
    .delete("/LeadNotes/ArchiveNotesById", {
      params: {
        notesId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const PrintLeadNotes = (leadId: string | undefined, moduleId: number | null) => {
  let params = {
    LeadId: leadId,
    ModuleId: moduleId,
  };
  return API()
    .post("/Reports/LeadNotesReport/GetLeadNotesReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let newDate = new Date();
          var toDay =
            newDate.getMonth() +
            1 +
            "-" +
            newDate.getDate() +
            "-" +
            newDate.getFullYear();
          let fileName =
            "LeadNotes" +
            "-" +
            toDay +
            ".pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};