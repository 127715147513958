import { ReportOutput } from '../../../enums/ReportOutput';
import { IIllinoisTranscriptReportParams } from '../../../interfaces/reports/transcript/IIllinoisTranscriptReportParams';
import { IStudentTanscriptReportparams } from '../../../interfaces/reports/transcript/IStudentTanscriptReportparams';
import { IStudentProgressReportParams } from '../../../interfaces/reports/ProgressReport/IStudentProgressReportParams';
import { ToBlob } from '../../../utils/base64Helper';
import { Download } from '../../../utils/file';
import API from '../../apiWrapper';
import Jszip from 'jszip';

export const getProgressReport = (
	body: any,
	fileName?: string
) => {
	return API()
		.post(
			'/Reports/ProgressReport/GetProgressReport',
			body,
			{ responseType: 'blob' }
		)
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};

export const getTranscriptReport = (body: any, fileName?: string) => {
	return API()
		.post('/Reports/TranscriptReport/GetTranscriptReport', body, {
			responseType: 'blob',
		})
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};

export const getIllinoisTranscriptReportAllStudents = (body: IIllinoisTranscriptReportParams, fileName?: string) => {
	return API()
		.post('/Reports/TranscriptReport/GetIllinoisTranscriptMultiple', {
			showSignature: body.showSignature,
			exportType: body.exportType,
			search: {
				campusId: body.campusId,
				programVersionId: (body.programVersionId && body.programVersionId.length && body.programVersionId) || null,
				studentGrpId: body.studentsGroup && body.studentsGroup.length ? body.studentsGroup : null,
				EnrollmentStatusId: body.enrollmentStatusId && body.enrollmentStatusId.length ? body.enrollmentStatusId : null,
			}
		}, {
		}).then(
			async (response: any) => {
				if (response && response.data && response.data.length > 0) {
					let extension = '';
					switch (Number(body.exportType)) {
					case Number(ReportOutput.Pdf):
						extension = 'pdf';
						break;
					case Number(ReportOutput.Excel):
						extension = 'xls';
						break;
					case Number(ReportOutput.Word):
						extension = 'doc';
						break;
					default:
						extension = 'pdf';
						break;
					}
					console.log(response.data.length);
					const link = document.createElement('a');
					document.body.appendChild(link);
					const zip = new Jszip();
					response.data.forEach((report: any, _index: number) => {
						const { item1: data, item2: enrollmentId } = report;
						zip.file(`${fileName || 'IllinoisTranscript'}-${enrollmentId}.${extension}`, ToBlob(data.base64Data), { base64: true });



						// setTimeout(() => {
						// 	const { item1: data, item2: enrollmentId } = report;
						// 	const binaryString = window.atob(data.base64Data);
						// 	const binaryLen = binaryString.length;
						// 	const bytes = new Uint8Array(binaryLen);
						// 	for (let i = 0; i < binaryLen; i++) {
						// 		const ascii = binaryString.charCodeAt(i);
						// 		bytes[i] = ascii;
						// 	}
						// 	const url = window.URL.createObjectURL(new Blob([bytes]));
						// 	link.href = url;
						// 	link.setAttribute('download', `${fileName || 'IllinoisTranscript'}-${enrollmentId}.${extension}`);
						// 	link.click();
						// }, index * 100);

					});

					const content = await zip.generateAsync({ type: 'blob' });
					link.href = window.URL.createObjectURL(content);
					link.setAttribute('download', `${fileName || 'IllinoisTranscript'}.zip`);
					link.click();
					document.body.removeChild(link);
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const getIllinoisTranscriptReport = (body: IIllinoisTranscriptReportParams, fileName?: string) => {
	if (!body.selectedStudents || body.selectedStudents.length === 0) {
		return API()
			.post('/Reports/TranscriptReport/GetIllinoisTranscript', body, {
				responseType: 'blob',
			})
			.then(
				(response: any) => {
					if (!fileName && ReportOutput.Pdf === body.exportType) {
						return response.data;
					}
					let extension = '';
					switch (Number(body.exportType)) {
					case Number(ReportOutput.Pdf):
						extension = 'pdf';
						break;
					case Number(ReportOutput.Excel):
						extension = 'xls';
						break;
					case Number(ReportOutput.Word):
						extension = 'doc';
						break;
					default:
						extension = 'pdf';
						break;
					}
					console.log('extension', extension);
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${fileName || 'Report'}.${extension}`);
					document.body.appendChild(link);
					link.click();
				},
				(error: any) => {
					return error;
				}
			);
	}
	const bodyData = {
		showSignature: body.showSignature,
		exportType: body.exportType
	};
	body.selectedStudents.forEach(({ currentEnrollmentId, studentNumber, studentName, campusId }) => {
		API().post('/Reports/TranscriptReport/GetIllinoisTranscript', { ...bodyData, stuEnrollId: currentEnrollmentId, campusId: campusId }, { responseType: 'blob' }).then(
			(response: any) => {
				if (!fileName && ReportOutput.Pdf === body.exportType) {
					return response.data;
				}
				let extension = '';
				switch (Number(body.exportType)) {
				case Number(ReportOutput.Pdf):
					extension = 'pdf';
					break;
				case Number(ReportOutput.Excel):
					extension = 'xls';
					break;
				case Number(ReportOutput.Word):
					extension = 'doc';
					break;
				default:
					extension = 'pdf';
					break;
				}
				console.log('extension', extension);
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileName || 'IllinoisTranscriptReport'}-${studentNumber}-${studentName}.${extension}`);
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
	});
	return Promise.resolve(

	);
};

export const getLedgerReport = (body: any, fileName?: string) => {
	return API()
		.post('/StudentAccounts/Ledger/GetLedgerReport', body, {
			responseType: 'blob',
		})
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};
export const getTSapNoticeReport = (body: any, _fileName?: string) => {
	return API()
		.post('/Reports/TitleIVNoticeReport/GetTitleIVNoticeReport', body)
		.then(
			(response) => {
				return response.data;
			},
			(error: any) => {
				return error;
			}
		);
};

export const getReport = (url: string, body: any) => {
	return API()
		.post(url, body)
		.then(
			(response) => {
				return response.data;
			},
			(error: any) => {
				return error;
			}
		);
};

export const getAttendanceHistoryReport = (body: any, fileName?: string) => {
	return API()
		.post('/AcademicRecords/Attendance/GetAttendanceHistoryReport', body, {
			responseType: 'blob',
		})
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};
export const getAidRecievedReport = (body: any, fileName?: string) => {
	return API()
		.post('/FinancialAid/StudentAward/GetAidRecievedReport', body, {
			responseType: 'blob',
		})
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};
export const getDisbursementNoticeReport = (body: any, fileName?: string) => {
	return API()
		.post('/FinancialAid/StudentAward/GetDisbursementNoticeReport', body, {
			responseType: 'blob',
		})
		.then(
			(response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ? fileName : 'Report.pdf');
				document.body.appendChild(link);
				link.click();
			},
			(error: any) => {
				return error;
			}
		);
};

export const getTranscriptReportMultiple = (body: IStudentTanscriptReportparams) => {
	const {
		orderBy,
		showCampusAddress,
		showCompletedHours,
		showCourseComponents,
		showCourseSummary,
		showCreditsColumn,
		showCurrentDate,
		showDateIssuedColumn,
		showGradPoints,
		showGradeScale,
		showGraduationDate,
		showHoursColumn,
		showLDA,
		showMultipleEnrollments,
		showPageNumber,
		showSSN,
		showSchoolFax,
		showSchoolName,
		showSchoolOfficialSignatureLine,
		showSchoolPhone,
		showSchoolWebsite,
		showStudentDOB,
		showStudentEmail,
		showStudentPhone,
		showStudentSignatureLine,
		showTermDescription,
		coursesLayout,
		campusId,
		enrollmentStatusId,
		selectedStudents,
		studentsGroup,
		isOfficialTranscript,
		noSchoolInformation,
		reportOutput,
		officialLogoPosition,
		programVersionId,
	
	} = body;
	if (body.allstudents) {
		return API().post('/Reports/TranscriptReport/GetTrascriptReportWithSearch',
			{
				search: {
					campusId,
					programVersionId,
					studentGrpId: studentsGroup && studentsGroup.length ? studentsGroup : undefined,
					enrollmentStatusId,
				},
				orderBy,
				showCampusAddress,
				showCompletedHours,
				showCourseComponents,
				showCourseSummary,
				showCreditsColumn,
				showCurrentDate,
				showDateIssuedColumn,
				showGradPoints,
				showGradeScale,
				showGraduationDate,
				showHoursColumn,
				showLDA,
				showMultipleEnrollments,
				showPageNumber,
				showSSN,
				showSchoolFax,
				showSchoolName,
				showSchoolOfficialSignatureLine,
				showSchoolPhone,
				showSchoolWebsite,
				showStudentDOB,
				showStudentEmail,
				showStudentPhone,
				showStudentSignatureLine,
				showTermDescription,
				coursesLayout,
				officialLogoPosition,
				noSchoolInformation,
				reportOutput,
				isOfficialTranscript,
			}).then(
			(response: any) => {
				let extension = '';
				switch (Number(body.reportOutput)) {
				case Number(ReportOutput.Excel):
					extension = 'xls';
					break;
				case Number(ReportOutput.Word):
					extension = 'doc';
					break;
				default:
					extension = 'pdf';
					break;
				}
				Download(
					ToBlob(response.data),
					`TranscriptReport.${extension}`);
			},
			(error: any) => {
				return error;
			}
		);
	} else {
		return API().post('/Reports/TranscriptReport/GetTrascriptReportSelectedStudents',
			{
				campusId,
				stuEnrollIdList: selectedStudents && selectedStudents.length ? selectedStudents.map(m => m.currentEnrollmentId).join(',') : [],
				orderBy,
				showCampusAddress,
				showCompletedHours,
				showCourseComponents,
				showCourseSummary,
				showCreditsColumn,
				showCurrentDate,
				showDateIssuedColumn,
				showGradPoints,
				showGradeScale,
				showGraduationDate,
				showHoursColumn,
				showLDA,
				showMultipleEnrollments,
				showPageNumber,
				showSSN,
				showSchoolFax,
				showSchoolName,
				showSchoolOfficialSignatureLine,
				showSchoolPhone,
				showSchoolWebsite,
				showStudentDOB,
				showStudentEmail,
				showStudentPhone,
				showStudentSignatureLine,
				showTermDescription,
				coursesLayout,
				officialLogoPosition,
				noSchoolInformation,
				reportOutput,
				isOfficialTranscript,
			}).then(
			(response: any) => {
				let extension = '';
				switch (Number(body.reportOutput)) {
				case Number(ReportOutput.Excel):
					extension = 'xls';
					break;
				case Number(ReportOutput.Word):
					extension = 'doc';
					break;
				default:
					extension = 'pdf';
					break;
				}
				Download(
					ToBlob(response.data),
					`TranscriptReport.${extension}`);
			},
			(error: any) => {
				return error;
			}
		);
	}
};


export const getProgressReportMultiple = (body: IStudentProgressReportParams) => {
	const {
		orderBy,
		showWeeklySchedule,
		showCurrentDate,
		showGraduationDate,
		showMultipleEnrollments,
		showPageNumber,
		showAllEnrollments,
		showSchoolOfficialSignatureLine,
		showStudentSignatureLine,
		campusId,
		enrollmentStatusId,
		selectedStudents,
		studentsGroup,
		showFinanceCalculations,
		reportOutput,
		programVersionId,
		showWorkUnitGrouping,
		showTermModule,
	} = body;
	if (body.allStudents) {
		return API().post('/Reports/ProgressReport/GetProgressReportWithSearch',
			{
				search: {
					campusId,
					programVersionId,
					studentGrpId: studentsGroup && studentsGroup.length ? studentsGroup : undefined,
					enrollmentStatusId: enrollmentStatusId && enrollmentStatusId.length > 0 ? enrollmentStatusId : null,
				},
				orderBy,
				showWeeklySchedule,
				showCurrentDate,
				showGraduationDate,
				showMultipleEnrollments,
				showPageNumber,
				showAllEnrollments,
				showSchoolOfficialSignatureLine,
				showStudentSignatureLine,
				reportOutput,
				showFinanceCalculations,
				studentsGroup,
				showWorkUnitGrouping,
				showTermModule,
			}).then(
			(response: any) => {
				let extension = '';
				switch (Number(body.reportOutput)) {
				case Number(ReportOutput.Excel):
					extension = 'xls';
					break;
				case Number(ReportOutput.Word):
					extension = 'doc';
					break;
				default:
					extension = 'pdf';
					break;
				}
				Download(
					ToBlob(response.data),
					`ProgressReport.${extension}`);
			},
			(error: any) => {
				return error;
			}
		);
	} else {
		return API().post('/Reports/ProgressReport/GetProgressReportSelectedStudents',
			{
				campusId,
				stuEnrollIdList: selectedStudents && selectedStudents.length ? selectedStudents.map(m => m.currentEnrollmentId).join(',') : [],
				orderBy,
				showWeeklySchedule,
				showCurrentDate,
				showGraduationDate,
				showMultipleEnrollments,
				showPageNumber,
				showAllEnrollments,
				showSchoolOfficialSignatureLine,
				showStudentSignatureLine,
				reportOutput,
				showFinanceCalculations,
				showWorkUnitGrouping,
				showTermModule,
			}).then(
			(response: any) => {
				let extension = '';
				switch (Number(body.reportOutput)) {
				case Number(ReportOutput.Excel):
					extension = 'xls';
					break;
				case Number(ReportOutput.Word):
					extension = 'doc';
					break;
				default:
					extension = 'pdf';
					break;
				}
				Download(
					ToBlob(response.data),
					`ProgressReport.${extension}`);
			},
			(error: any) => {
				return error;
			}
		);
	}
};
