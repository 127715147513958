import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  FormLabel,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import * as reportApi from "../../../api/reports/progressReport/reportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { IStudentProgressReportParams } from "../../../interfaces/reports/ProgressReport/IStudentProgressReportParams";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import {
  actions as reportActions,
  selectors as reportSelectors,
} from "../../../store/reports/transcriptReport";
import { useActions } from "../../../store/utils";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { Scrollbars, positionValues } from "react-custom-scrollbars";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { CardBody } from "reactstrap";

const LogoPositions: {
  [key: string]: string;
} = {
  "Left Justified": "Left",
  "Centered(School Info not shown)": "Center",
  "Right Justified": "Right",
  "No Logo Shown": "NoLogo",
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    scroll: {
      "& div": {
        zIndex: 5,
      },
    },
    optionsCard: {
      padding: theme.spacing(2, 1),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const ProgressReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const actions = useActions(
    {
      getStudents: reportActions.loadStudents,
      loadMoreStudents: reportActions.loadMoreStudents,
      addToSelectedStudents: reportActions.addToSelected,
      removeFromSelectedStudents: reportActions.remove,
      reset: reportActions.reset,
    },
    []
  );
  const loadingMoreStudents = useSelector(reportSelectors.loadingMore);
  const searchingStudents = useSelector(reportSelectors.loading);
  const studentsList: Array<StudentProfile> = useSelector(reportSelectors.data);
  const selectedStudents: Array<StudentProfile> = useSelector(
    reportSelectors.selected
  );
  const filters = useSelector(reportSelectors.filters);
  const hasMore = useSelector(reportSelectors.hadMore);
  const data = useForm<any>({ mode: "onBlur" });
  const [tab, setTab] = React.useState<number>(1);
  const [model, setModel] = React.useState<IStudentProgressReportParams>({
    reportOutput: ReportOutput.Pdf,
    campusId: userSelectedCampus,
    studentsGroup: [],
    inactiveStudentGroup: false,
    programVersionId: [],
    enrollmentStatusId: [],
    nameBadgeId: "",
    selectedStudents: [],
    allStudents: false,
    orderBy: "LastName Asc,FirstName Asc,MiddleName Asc",
    showFinanceCalculations: false,
    showCurrentDate: true,
    showWeeklySchedule: false,
    showAllEnrollments: false,
    showMultipleEnrollments: false,
    showGraduationDate: true,
    showStudentSignatureLine: false,
    showSchoolOfficialSignatureLine: false,
    showPageNumber: true,
    showTermModule: true,
    showWorkUnitGrouping: false,
  });

  const handleFieldOnChange = (
    fieldId: keyof IStudentProgressReportParams,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = { ...model };
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const handleCampusChange = (value: any) => {
    if (model.campusId != value) {
      let updatedModel = { ...model };
      updatedModel.campusId = value;
      setModel({ ...updatedModel, studentsGroup: [], programVersionId: [] });
    }
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async () => {
    let params = (await getReportParameters()) as IStudentProgressReportParams;
    const validation = await data.triggerValidation();
    if (validation && (params.allStudents == true || (params.selectedStudents && selectedStudents.length > 0))) {
      await reportApi.getProgressReportMultiple({ ...params });
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
      actions.reset();
      actions.addToSelectedStudents(data.selectedStudents || []);
    } else {
      setModel({
        reportOutput: ReportOutput.Pdf,
        campusId: userSelectedCampus,
        studentsGroup: [],
        inactiveStudentGroup: false,
        programVersionId: [],
        enrollmentStatusId: [],
        nameBadgeId: "",
        selectedStudents: [],
        allStudents: false,
        orderBy: "LastName Asc,FirstName Asc,MiddleName Asc",
        showFinanceCalculations: false,
        showCurrentDate: true,
        showWeeklySchedule: false,
        showAllEnrollments: false,
        showMultipleEnrollments: false,
        showGraduationDate: true,
        showStudentSignatureLine: false,
        showSchoolOfficialSignatureLine: false,
        showPageNumber: true,
        showTermModule: true,
        showWorkUnitGrouping: false,
      });
    }
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IStudentProgressReportParams = {
        ...model,
      };
      return params;
    }
    return {} as IStudentProgressReportParams;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || ({} as DropDownListItem);
  };
  const [checked, setChecked] = React.useState<Array<boolean>>([]);
  const [toRemove, setToRemove] = React.useState<Array<boolean>>([]);
  const searchStudents = () => {
    const {
      campusId,
      studentsGroup,
      enrollmentStatusId,
      programVersionId,
      nameBadgeId,
    } = model;
    actions.getStudents({
      CampusId: campusId || "",
      StudentGrpId: studentsGroup && studentsGroup.length ? studentsGroup : "",
      EnrollmentStatusId:
        enrollmentStatusId && enrollmentStatusId.length
          ? enrollmentStatusId
          : "",
      ProgramVersionId:
        programVersionId && programVersionId.length ? programVersionId : "",
      StartDate: null,
      NameBadgeId: nameBadgeId || "",
      skip: 0,
      take: 15,
    });
    setChecked([]);
  };

  const loadMoreStudents = () => {
    actions.loadMoreStudents({
      ...filters,
      skip: studentsList.length,
    });
  };

  const isSelected = (stuEnrollmentId: string) =>
    selectedStudents.findIndex(
      (m) => stuEnrollmentId === m.currentEnrollmentId
    ) !== -1;

  React.useEffect(() => {
    handleFieldOnChange(
      "selectedStudents",
      selectedStudents,
      (v: StudentProfile) => v
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudents]);

  React.useEffect(() => {
    return () => {
      actions.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowWorkUnitGrouping = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;    
    (updatedModel as any)["showWorkUnitGrouping"] = (
      event.target as HTMLInputElement
    ).value;
    setModel({ ...updatedModel });
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Tabs
              value={tab}
              onChange={(e, newValue) => setTab(newValue)}
              aria-label="Report Options Tabs"
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Student Search" value={1} />
              <Tab label="Sorting" value={2} />
              <Tab label="Options" value={3} />
            </Tabs>
            {tab === 1 && (
              <Fragment>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={4} xs={12}>
                    <CampusForCurrentUserAutoComplete
                      name="campusId"
                      valueFilter={
                        model.campusId
                          ? {
                              key: "value",
                              values: [model.campusId],
                            }
                          : undefined
                      }
                      filterHandle={(v: any) =>
                        handleCampusChange((v && v.value) || undefined)
                      }
                      error={!!data.errors.campusId}
                      helperText={
                        data.errors.campusId && data.errors.campusId.message
                      }
                      inputRef={data.register({
                        required: "Campus is required.",
                      })}
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}>
                    <ProgramVersionAutoComplete
                      multiple
                      chipSize="small"
                      maxTags={1}
                      campusId={model.campusId}
                      label={"Program Version(s)"}
                      valueFilter={
                        model.programVersionId &&
                        model.programVersionId.length > 0
                          ? {
                              key: "value",
                              values: model.programVersionId,
                            }
                          : undefined
                      }
                      filterHandle={(v: any) =>
                        handleFieldOnChange(
                          "programVersionId",
                          v,
                          (value: any) => value.map((item: any) => item.value)
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={4} xs={12}>
                    <EnrollmentStatusAutoComplete
                      multiple
                      campusId={model.campusId}
                      chipSize="small"
                      maxTags={1}
                      label={"Enrollment Status(es)"}
                      valueFilter={
                        model.enrollmentStatusId &&
                        model.enrollmentStatusId.length > 0
                          ? {
                              key: "value",
                              values: model.enrollmentStatusId,
                            }
                          : undefined
                      }
                      filterHandle={(v: any) =>
                        handleFieldOnChange(
                          "enrollmentStatusId",
                          v,
                          (value: any) => value.map((item: any) => item.value)
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <StudentGroupsAutoComplete
                      label={"Student Group(s)"}
                      chipSize="small"
                      maxTags={1}
                      params={{
                        inActiveStudentGroup: model.inactiveStudentGroup,
                      }}
                      campusId={model.campusId}
                      valueFilter={
                        model.studentsGroup && model.studentsGroup.length > 0
                          ? {
                              key: "value",
                              values: model.studentsGroup,
                            }
                          : undefined
                      }
                      filterHandle={(v: any) =>
                        handleFieldOnChange("studentsGroup", v, (value: any) =>
                          value.map((item: any) => item.value)
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                    <FormControlLabel
                      label="InActive Student Groups"
                      control={
                        <Switch
                          checked={model.inactiveStudentGroup}
                          value="inActiveStudentGroup"
                          onChange={() =>
                            setModel({
                              ...model,
                              inactiveStudentGroup: !model.inactiveStudentGroup,
                              studentsGroup: [],
                            })
                          }
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>

                {!model.allStudents && (
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <InputField
                        type={InputType.TEXT}
                        label="Student Name, Badge Id"
                        name="nameBadgeId"
                        value={model.nameBadgeId}
                        onChange={(e) =>
                          handleFieldOnChange(
                            "nameBadgeId",
                            e?.target?.value ?? ""
                          )
                        }
                        multiline
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      id="lblSingleStudent"
                      className={classes.switch}
                      labelPlacement="end"
                      label="Print All"
                      control={
                        <Switch
                          checked={model.allStudents}
                          name="allStudetns"
                          onChange={(e: any) => {
                            model.allStudents = e ? e.target.checked : false;
                            setModel({ ...model });
                          }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                {model.allStudents && (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography variant="body2" color="error" component="p">
                        Selecting Print All may require additional time to
                        generate the report.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!model.allStudents && (
                  <React.Fragment>
                    {" "}
                    <Grid container spacing={1}>
                      <Grid item>
                        <ProgressSaveButton
                          onClick={handleSubmit(searchStudents)}
                          type="button"
                          text="Search"
                          loading={searchingStudents}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Card>
                          <CustomCardTitle title="Search Result(s)" />
                          <CardContent>
                            <TableContainer>
                              <Scrollbars
                                className={classes.scroll}
                                style={{ height: 250 }}
                                renderView={(props) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                                onScrollFrame={(val: positionValues) => {
                                  if (
                                    !searchingStudents &&
                                    hasMore &&
                                    val.top >= 1
                                  ) {
                                    loadMoreStudents();
                                  }
                                }}
                              >
                                <Table
                                  size="small"
                                  aria-label="simple table"
                                  stickyHeader
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell variant="head"></TableCell>
                                      <TableCell variant="head" align="left">
                                        Badge ID
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Name
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Program Version
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Enrollment Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {searchingStudents && (
                                      <TableRow>
                                        <TableCell colSpan={5} align="center">
                                          <CircularProgress
                                            size={50}
                                            color="primary"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    {!searchingStudents &&
                                      studentsList.length === 0 && (
                                        <TableRow>
                                          <TableCell colSpan={5} align="center">
                                            No records found.
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    {!searchingStudents &&
                                      studentsList.length > 0 &&
                                      studentsList.map(
                                        (student, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell align="left">
                                              <Checkbox
                                                disabled={isSelected(
                                                  student.currentEnrollmentId ||
                                                    ""
                                                )}
                                                color="primary"
                                                checked={!!checked[index]}
                                                onChange={() => {
                                                  const newChecked = [
                                                    ...checked,
                                                  ];
                                                  newChecked[index] = !(
                                                    newChecked[index] || false
                                                  );
                                                  setChecked(newChecked);
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.studentNumber}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.studentName}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.currentProgramVersion}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.currentStatus}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    {loadingMoreStudents && (
                                      <TableRow>
                                        <TableCell colSpan={5} align="center">
                                          <CircularProgress
                                            size={50}
                                            color="primary"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </Scrollbars>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const selectedStudents = studentsList.filter(
                              (_student, index: number) => checked[index]
                            );
                            actions.addToSelectedStudents(selectedStudents);
                            setChecked([]);
                          }}
                        >
                          Select Student(s)
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const selectedStudents = toRemove
                              .map((m, i) => ({
                                checked: !!m || false,
                                index: i,
                              }))
                              .filter((m) => m.checked)
                              .map((m) => m.index);
                            actions.removeFromSelectedStudents(
                              selectedStudents
                            );
                            setToRemove([]);
                          }}
                        >
                          Remove Student(s)
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Card>
                          <CustomCardTitle title="Selected Students" />
                          <CardContent>
                            <TableContainer>
                              <Scrollbars
                                className={classes.scroll}
                                style={{ height: 250 }}
                                renderView={(props) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...props.style,
                                      overflowX: "hidden",
                                    }}
                                  />
                                )}
                              >
                                <Table size="small" stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell variant="head"></TableCell>
                                      <TableCell variant="head" align="left">
                                        Badge ID
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Name
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Program Version
                                      </TableCell>
                                      <TableCell variant="head" align="left">
                                        Enrollment Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {selectedStudents.length === 0 && (
                                      <TableRow>
                                        <TableCell colSpan={5} align="center">
                                          No Students Selected.
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    {selectedStudents.length > 0 &&
                                      selectedStudents.map(
                                        (student, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell align="left">
                                              <Checkbox
                                                color="primary"
                                                checked={!!toRemove[index]}
                                                onChange={() => {
                                                  const newChecked = [
                                                    ...toRemove,
                                                  ];
                                                  newChecked[index] = !(
                                                    newChecked[index] || false
                                                  );
                                                  setToRemove(newChecked);
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.studentNumber}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.studentName}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.currentProgramVersion}
                                            </TableCell>
                                            <TableCell align="left">
                                              {student.currentStatus}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </Scrollbars>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Fragment>
            )}
            {tab === 2 && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Sort By</TableCell>
                          <TableCell variant="head">Sort Order</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {model.orderBy.split(",").map((item, index) => (
                          <TableRow key={`row-${index}`}>
                            {item.split(" ").map((subItem, subIndex) => (
                              <TableCell key={`${index}-${subIndex}`}>
                                {subIndex === 0 ? (
                                  subItem
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      let orderBy = model.orderBy.split(",");
                                      orderBy[index] = orderBy[index]
                                        .split(" ")
                                        .map((m, i) =>
                                          i === 1
                                            ? m === "Asc"
                                              ? "Desc"
                                              : "Asc"
                                            : m
                                        )
                                        .join(" ");
                                      setModel({
                                        ...model,
                                        orderBy: orderBy.join(","),
                                      });
                                    }}
                                  >
                                    {subItem === "Asc"
                                      ? "Ascending"
                                      : "Descending"}
                                  </Button>
                                )}
                              </TableCell>
                            ))}
                            <TableCell key={`${index}-${2}`}>
                              {index > 0 && (
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    let orderBy = model.orderBy.split(",");
                                    let temp = orderBy[index];
                                    orderBy[index] = orderBy[index - 1];
                                    orderBy[index - 1] = temp;
                                    setModel({
                                      ...model,
                                      orderBy: orderBy.join(","),
                                    });
                                  }}
                                >
                                  <ArrowUpward />
                                </IconButton>
                              )}
                              {index < model.orderBy.split(",").length - 1 && (
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    let orderBy = model.orderBy.split(",");
                                    let temp = orderBy[index];
                                    orderBy[index] = orderBy[index + 1];
                                    orderBy[index + 1] = temp;
                                    setModel({
                                      ...model,
                                      orderBy: orderBy.join(","),
                                    });
                                  }}
                                >
                                  <ArrowDownward />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            {tab === 3 && (
              <Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CustomCardTitle title="Layout Options" />
                      <CardBody className={classes.optionsCard}>
                        <Grid container spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl
                              component="fieldset"
                              className={classes.fieldSet}
                            >
                              <RadioGroup
                                aria-label="showWorkUnitGrouping"
                                name="showWorkUnitGrouping"
                                value={model.showWorkUnitGrouping?.toString()}
                                onChange={handleShowWorkUnitGrouping}
                              >
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="false"
                                  control={<Radio />}
                                  label="Do not show work units under each subject"
                                />
                                <FormControlLabel
                                  labelPlacement="end"
                                  value="true"
                                  control={<Radio />}
                                  label="Show work units under each subject"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {/* <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Financial Information"
                              control={
                                <Switch
                                  checked={model.showFinanceCalculations}
                                  name="showFinanceCalculations"
                                  onChange={(e: any) => {
                                    model.showFinanceCalculations = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Student Signature Line"
                              control={
                                <Switch
                                  checked={model.showStudentSignatureLine}
                                  name="showStudentSignatureLine"
                                  onChange={(e: any) => {
                                    model.showStudentSignatureLine = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid> */}
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CustomCardTitle title="Miscellaneous Options" />
                      <CardBody className={classes.optionsCard}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Financial Information"
                              control={
                                <Switch
                                  checked={model.showFinanceCalculations}
                                  name="showFinanceCalculations"
                                  onChange={(e: any) => {
                                    model.showFinanceCalculations = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Student Signature Line"
                              control={
                                <Switch
                                  checked={model.showStudentSignatureLine}
                                  name="showStudentSignatureLine"
                                  onChange={(e: any) => {
                                    model.showStudentSignatureLine = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show School Official Signature Line"
                              control={
                                <Switch
                                  checked={
                                    model.showSchoolOfficialSignatureLine
                                  }
                                  name="showSchoolOfficialSignatureLine"
                                  onChange={(e: any) => {
                                    model.showSchoolOfficialSignatureLine = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Weekly Schedule"
                              control={
                                <Switch
                                  checked={model.showWeeklySchedule}
                                  name="showWeeklySchedule"
                                  onChange={(e: any) => {
                                    model.showWeeklySchedule = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Term/Module"
                              control={
                                <Switch
                                  checked={model.showTermModule}
                                  name="showTermModule"
                                  onChange={(e: any) => {
                                    model.showTermModule = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show All Enrollments"
                              control={
                                <Switch
                                  checked={model.showAllEnrollments}
                                  name="showSchoolFax"
                                  onChange={(e: any) => {
                                    model.showAllEnrollments = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          {/* <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Page Number"
                              control={
                                <Switch
                                  checked={model.showPageNumber}
                                  name="showPageNumber"
                                  onChange={(e: any) => {
                                    model.showPageNumber = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              className={classes.switch}
                              labelPlacement="end"
                              label="Show Report Date"
                              control={
                                <Switch
                                  checked={model.showCurrentDate}
                                  name="showCurrentDate"
                                  onChange={(e: any) => {
                                    model.showCurrentDate = e
                                      ? e.target.checked
                                      : false;
                                    setModel({ ...model });
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid> */}
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                </Grid>
              </Fragment>
            )}
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <Autocomplete
                  {...exportTypeAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldOnChange(
                      "reportOutput",
                      value ? value : undefined,
                      (v: any) => v?.id
                    );
                  }}
                  value={getSelectedItem(
                    exportTypes,
                    model.reportOutput?.toString() ?? ""
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Export Type"
                      name="exportType"
                      error={!!data.errors.exportType}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.exportType
                          ? "Export Type is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      hidePreview
    />
  );
};
export default ProgressReport;
