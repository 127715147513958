import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import {
  deleteTerm,
  getDetails,
  getTermsList,
  saveTerm,
  saveTermExist,
} from "../../../../api/setup/terms/termslist";
import { useForm } from "react-hook-form";
import ITermDetails from "../../../../interfaces/setup/terms/ITermDetails";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import AcademicCalendarAutoComplete from "../../../../components/AutoComplete/AcademicCalendarAutoComplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { EmptyGuid } from "../../../../utils/constants";
import APIHook from "../../../../api/hook";
import ITerm from "../../../../interfaces/setup/terms/ITerm";
import { Pagination } from "@material-ui/lab";
import { toastr } from "react-redux-toastr";
import SortIcon from "@material-ui/icons/Sort";
import { InputType } from "../../../../constants/uiConstants/inputConstants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardMain: {
      margin: theme.spacing(2, 0),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    cardMainContent: {
      height: "100%",
    },
    selectAbleRow: {
      cursor: "pointer",
    },
    pagination: {
      display: "inline-flex",
    },
    loaderWrapper: {
      textAlign: "center",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
  })
);
const Terms: React.FC = () => {
  const classes = useStyles({});
  const [page, setPage] = React.useState<number>(1);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"asc" | "desc">("desc");
  const pageSize = 20;
  const [search, setSearch] = React.useState<string>("");
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [popAnchor, setPopAnchor] = React.useState<null | HTMLElement>(null);
  const [popMessage, setPopMessage] = React.useState<string>("");
  const [popConfirmAction, setPopConfirmAction] = React.useState<() => void>();
  const [, message, code, saving, callSave, resetState] = APIHook<
    ITermDetails,
    typeof saveTerm
  >(saveTerm);
  const [, , codeExist, savingExist, callSaveExist, resetStateExist] = APIHook<
    ITermDetails,
    typeof saveTermExist
  >(saveTermExist);
  const [, messageDelete, codeDelete, deleting, callDelete, resetStateDelete] =
    APIHook<string, typeof deleteTerm>(deleteTerm);

  const [
    fetchedDetails,
    ,
    detailsCode,
    loadingDetails,
    getTermDetails,
    resetDetails,
  ] = APIHook<ITermDetails, typeof getDetails>(getDetails);
  const [data, , , loading, getList] = APIHook<
    {
      data: Array<ITerm>;
      hasMoreData: boolean;
      total: number;
    },
    typeof getTermsList
  >(getTermsList);
  React.useEffect(() => {
    getList({
      take: pageSize,
      sort,
      showAll,
      skip: (page - 1) * pageSize,
      search: search || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [details, setDetails] = React.useState<ITermDetails>({
    id: EmptyGuid,
  }); // eslint-disable-line
  const { register, reset, errors, handleSubmit, triggerValidation } =
    useForm<ITermDetails>({
      mode: "onBlur",
    });

  const onSave = () => {
    callSave(details);
  };

  const onSaveExist = () => {
    callSaveExist(details);
  };

  React.useEffect(() => {
    if (detailsCode === 200) {
      reset({
        ...fetchedDetails,
      });
      setDetails({ ...fetchedDetails });
      setDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsCode, loadingDetails]);

  React.useEffect(() => {
    if ((code === 200 && !saving) || (codeExist === 200 && !savingExist)) {
      toastr.success("", "Term saved successfully.");
      setTimeout(() => {
        toastr.clean();
      }, 3000);
      resetDetails();
      setDetails({
        id: EmptyGuid,
      });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
      });
    } else if (code) {
      toastr.error("", message || "Something went wrong.");
    }
    resetState(); //This resets the code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, saving, codeExist, savingExist]);

  React.useEffect(() => {
    if (codeDelete === 200 && !deleting) {
      toastr.success("", "Term deleted successfully.");
      resetDetails();
      setDetails({
        id: EmptyGuid,
      });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
      });
    } else if (codeDelete) {
      toastr.error("", "Class is registered with this term.");
    }
    resetStateDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDelete, deleting]);

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Card elevation={12} className={classes.cardMain}>
          <CardContent className={classes.cardMainContent}>
            <Grid container spacing={1} className={classes.cardMainContent}>
              <Grid item xs={6} className={classes.cardMainContent}>
                <Card elevation={2} className={classes.cardMainContent}>
                  <CardContent className={classes.cardMainContent}>
                    <TableContainer className={classes.cardMainContent}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">
                              <InputField
                                value={search}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setSearch(e.target.value);
                                  if (
                                    !e.target.value ||
                                    e.target.value.length > 2
                                  ) {
                                    setPage(1);
                                    getList({
                                      take: pageSize,
                                      skip: 0,
                                      search: e.target.value,
                                      showAll,
                                      sort,
                                    });
                                  }
                                }}
                                label="Terms Search"
                              />
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={showAll}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setShowAll(e.target.checked);
                                      setPage(1);
                                      getList({
                                        take: pageSize,
                                        skip: 0,
                                        search,
                                        showAll: e.target.checked,
                                        sort,
                                      });
                                    }}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Show All"
                              />
                              <IconButton
                                onClick={() => {
                                  const newSort =
                                    sort === "asc" ? "desc" : "asc";
                                  setSort(newSort);
                                  setPage(1);
                                  getList({
                                    take: pageSize,
                                    skip: 0,
                                    search,
                                    showAll,
                                    sort: newSort,
                                  });
                                }}
                              >
                                <SortIcon
                                  className={
                                    (sort === "asc" && classes.sortDesc) || ""
                                  }
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading && (
                            <TableRow>
                              <TableCell align="center">
                                <CircularProgress size={50} color="primary" />
                              </TableCell>
                            </TableRow>
                          )}
                          {!loading &&
                            data?.data?.map((item: ITerm) => {
                              return (
                                <TableRow
                                  className={classes.selectAbleRow}
                                  key={item.id}
                                  onClick={(e) => {
                                    if (!dirty) {
                                      getTermDetails(item.id);
                                    } else {
                                      setPopMessage(
                                        "You have unsaved changes. Are you sure you want to proceed?"
                                      );
                                      setPopConfirmAction(() => () => {
                                        getTermDetails(item.id);
                                      });
                                      setPopAnchor(e.currentTarget);
                                    }
                                  }}
                                >
                                  <TableCell>
                                    {item.code}-{item.name}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {(data?.data?.length || 0) < pageSize &&
                            !loading &&
                            [
                              ...Array(pageSize - (data?.data?.length || 0)),
                            ].map((_, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>&nbsp;</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell align="center">
                              <Pagination
                                className={classes.pagination}
                                disabled={
                                  (!data?.hasMoreData ?? true) &&
                                  (page || 1) === 1
                                }
                                onChange={(_e, page) => {
                                  setPage(page);
                                  getList({
                                    take: pageSize,
                                    skip: (page - 1) * pageSize,
                                    search: search || undefined,
                                    showAll,
                                    sort,
                                  });
                                }}
                                count={
                                  Math.ceil((data?.total || 0) / pageSize) || 0
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card elevation={2}>
                  <CardContent>
                    {/* <form onSubmit={handleSubmit(onSave)}> */}
                    <form
                      onSubmit={handleSubmit(
                        details.id == EmptyGuid ? onSave : onSaveExist
                      )}
                    >
                      {((loadingDetails ||
                        deleting ||
                        saving ||
                        savingExist) && (
                        <div className={classes.loaderWrapper}>
                          <CircularProgress size={50} color="primary" />
                        </div>
                      )) || (
                        <Fragment>
                          <InputField
                            required
                            name="code"
                            label="Term Code"
                            inputRef={register({
                              required: "Term Code is required.",
                            })}
                            error={errors.code ? true : false}
                            helperText={errors.code ? errors.code.message : ""}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              if (
                                (details.code || "") !== (e.target.value || "")
                              ) {
                                setDetails({
                                  ...details,
                                  code: e.target.value,
                                });
                                setDirty(true);
                              }
                            }}
                          />
                          <InputField
                            required
                            name="name"
                            label="Term Description"
                            inputRef={register({
                              required: "Term Description is required.",
                            })}
                            error={errors.name ? true : false}
                            helperText={errors.name ? errors.name.message : ""}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              if (details.name !== e.target.value) {
                                setDetails({
                                  ...details,
                                  name: e.target.value,
                                });

                                setDirty(true);
                              }
                            }}
                          />
                          <StatusAutoComplete
                            error={errors.status ? true : false}
                            helperText={
                              errors.status ? errors.status.message : ""
                            }
                            label="Status *"
                            name="status"
                            inputRef={register({
                              required: "Status is required.",
                            })}
                            valueFilter={
                              details?.status
                                ? {
                                    key: "value",
                                    values: [details?.status],
                                  }
                                : undefined
                            }
                            filterHandle={(value: any) => {
                              setDetails({
                                ...details,
                                status: value.value || undefined,
                              });
                              if (details.status !== value?.value) {
                                setDirty(true);
                              }
                            }}
                          />
                          <AcademicCalendarAutoComplete
                            error={errors.termType ? true : false}
                            helperText={
                              errors.termType ? errors.termType.message : ""
                            }
                            label="Term Type *"
                            name="termType"
                            inputRef={register({
                              required: "Term Type is required.",
                            })}
                            valueFilter={
                              details?.termType
                                ? {
                                    key: "value",
                                    values: [details?.termType],
                                  }
                                : undefined
                            }
                            filterHandle={(value: any) => {
                              console.log("value", value);
                              setDetails({
                                ...details,
                                termType:
                                  (value?.value && Number(value.value)) ||
                                  undefined,
                              });
                              if (details.termType !== value?.value) {
                                setDirty(true);
                              }
                            }}
                            defaultSelectedFirstItem={false}
                          />
                          <InputField
                            required
                            type={InputType.NUMBER}
                            label="Instructional Weeks"
                            name="instructionalWeeks"
                            inputRef={register({
                              required: "Instructional Weeks is required.",
                              maxLength: {
                                value: 7,
                                message:
                                  "Max value for instructional week is 9999999",
                              },
                              validate: () =>
                                parseInt(
                                  (details.instructionalWeeks || 0).toString()
                                ) !== details.instructionalWeeks
                                  ? "Instructional Weeks must be a number."
                                  : true,
                            })}
                            error={errors.instructionalWeeks ? true : false}
                            helperText={
                              errors.instructionalWeeks
                                ? errors.instructionalWeeks.message
                                : ""
                            }
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              if (
                                parseInt(e.target.value) !==
                                details.instructionalWeeks
                              ) {
                                setDetails({
                                  ...details,
                                  instructionalWeeks: parseInt(e.target.value),
                                });
                                setDirty(true);
                              }
                            }}
                          />
                          <KeyboardDatePicker
                            disableToolbar
                            required
                            name="startDate"
                            fullWidth
                            format="MM/dd/yyyy"
                            label="Term Start"
                            value={details?.startDate || null}
                            onChange={(date: any) => {
                              if (details.startDate !== date) {
                                setDetails({
                                  ...details,
                                  startDate: date,
                                });
                                setDirty(true);
                              }
                              setTimeout(() => {
                                triggerValidation(["startDate"]);
                              }, 50);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputRef={register({
                              required: "Term Start is required.",
                            })}
                            error={errors.startDate ? true : false}
                            helperText={
                              errors.startDate ? errors.startDate.message : ""
                            }
                          />
                          <KeyboardDatePicker
                            fullWidth
                            required
                            name="endDate"
                            format="MM/dd/yyyy"
                            label="Term End"
                            value={details?.endDate || null}
                            onChange={(date: any) => {
                              if (details.endDate !== date) {
                                setDetails({
                                  ...details,
                                  endDate: date,
                                });
                                setDirty(true);
                              }
                              setTimeout(() => {
                                triggerValidation(["endDate"]);
                              }, 50);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputRef={register({
                              required: "Term End is required.",
                              validate: () =>
                                details?.startDate &&
                                details?.endDate &&
                                details?.startDate > details?.endDate
                                  ? "Term End must be greater than Term Start."
                                  : true,
                            })}
                            error={errors.endDate ? true : false}
                            helperText={
                              errors.endDate ? errors.endDate.message : ""
                            }
                          />
                        </Fragment>
                      )}
                    </form>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      variant="contained"
                      //   onClick={handleSubmit(onSave)}
                      onClick={handleSubmit(
                        details.id == EmptyGuid ? onSave : onSaveExist
                      )}
                      disabled={
                        saving || deleting || loadingDetails || savingExist
                      }
                    >
                      SAVE
                    </Button>{" "}
                    <Button
                      disabled={
                        saving || deleting || loadingDetails || savingExist
                      }
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        if (!dirty) {
                          resetDetails();
                          setDetails({
                            id: EmptyGuid,
                          });
                          reset({});
                          setDirty(false);
                        } else {
                          setPopMessage(
                            "You have unsaved changes. Are you sure you want to proceed?"
                          );
                          setPopAnchor(e.currentTarget);
                          setPopConfirmAction(() => () => {
                            resetDetails();
                            setDetails({
                              id: EmptyGuid,
                            });
                            reset({});
                            setDirty(false);
                          });
                        }
                      }}
                    >
                      NEW
                    </Button>
                    {details?.id && details?.id !== EmptyGuid && (
                      <Button
                        disabled={
                          saving || deleting || loadingDetails || savingExist
                        }
                        color="secondary"
                        variant="contained"
                        onClick={(e) => {
                          setPopMessage(
                            "Are you sure you want to delete this term?"
                          );
                          setPopAnchor(e.currentTarget);
                          setPopConfirmAction(() => () => {
                            callDelete(details?.id || EmptyGuid);
                          });
                        }}
                      >
                        DELETE
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </MuiPickersUtilsProvider>
      <Popover
        anchorEl={popAnchor}
        open={Boolean(popAnchor)}
        onClose={() => {
          setPopAnchor(null);
          setPopMessage("");
          setPopConfirmAction(undefined);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">{popMessage}</Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                if (popConfirmAction) {
                  popConfirmAction();
                }
                setPopAnchor(null);
                setPopMessage("");
                setPopConfirmAction(undefined);
              }}
            >
              Confirm
            </Button>{" "}
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => {
                setPopAnchor(null);
                setPopMessage("");
                setPopConfirmAction(undefined);
              }}
            >
              CANCEL
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </React.Fragment>
  );
};

export default Terms;
