import API from "../../../apiWrapper";
import { Email } from "../../../../interfaces/student/profile/contactInfo/Email";

export const getBestEmail = (studentId: string | undefined) => {
  return API()
    .get("/leads/" + studentId + "/email/GetBest")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data as Email;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const updateEmail = (leadId: string | undefined, emailObj: Email) => {
  return API()
    .put("/leads/" + leadId + "/email/Put", {...emailObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
